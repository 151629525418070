import React, { useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { DataContext } from '../../contexts/dataContext';
import Header from './Header';
import MainMenu from './MainMenu';
import theme from '../../styles/theme.styles';

// Helpers
import Loading from '../Loading';

const mainMenuItems = [
  {
    label: 'Athletes',
    path: '/app/',
    color: theme.colors.royalBlue,
    icon: (
      <svg width={22} height={22} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M.334 11.167c0 5.885 4.78 10.666 10.667 10.666 5.886 0 10.666-4.78 10.666-10.666S16.887.5 11.001.5.334 5.28.334 11.167zm2.407 0c0-4.113 3.029-7.538 6.97-8.16-.2 2.264-.853 4.364-1.949 6.267-1.1 1.913-2.607 3.531-4.479 4.834a8.21 8.21 0 01-.542-2.941zm8.25-2.925a17.173 17.173 0 01-2.546 4.391 17.155 17.155 0 015.093-.003 17.125 17.125 0 01-2.546-4.388zm7.725 5.873a8.21 8.21 0 00.544-2.948c0-4.117-3.034-7.545-6.982-8.161.2 2.264.854 4.365 1.95 6.269 1.103 1.915 2.612 3.535 4.488 4.84zM4.59 16.368a8.25 8.25 0 006.41 3.059 8.25 8.25 0 006.409-3.058c-2.059-.96-4.207-1.45-6.41-1.45-2.202 0-4.35.49-6.409 1.45z"
          fill="#fff"
        />
      </svg>),
  },
  {
    label: 'Agencies',
    path: '/agencies/',
    color: theme.colors.royalBlue,
    icon: (
      <svg width={22} height={23} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.001.833C5.111.833.334 5.609.334 11.5c0 5.89 4.776 10.666 10.667 10.666 5.892 0 10.667-4.775 10.667-10.666S16.893.833 11.001.833zm.424 4.267v1.723l3.544.982a1 1 0 01.732.964v1.67a7.315 7.315 0 01-1.442 4.371 7.669 7.669 0 01-2.834 2.348v1.809a9.328 9.328 0 004.213-3.19 8.935 8.935 0 001.763-5.339v-2.92a1 1 0 00-.733-.964L11.425 5.1zm-3.683 9.71a7.669 7.669 0 002.834 2.348v1.809a9.328 9.328 0 01-4.213-3.19A8.935 8.935 0 014.6 10.439v-2.92a1 1 0 01.732-.964L10.576 5.1v1.722l-3.543.983a1 1 0 00-.733.963v1.67c0 1.582.498 3.093 1.442 4.372z"
          fill="#fff"
        />
      </svg>),
  },
  {
    label: 'Favorites',
    path: '/favorites/',
    color: theme.colors.profileFavorite,
    icon: (
      <svg width={18} height={17} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.219.113c-.977 0-1.872.327-2.662.971C9.8 1.702 9.297 2.49 9 3.061c-.297-.572-.8-1.359-1.557-1.977C6.653.44 5.758.114 4.78.114 2.056.113 0 2.466 0 5.586c0 3.372 2.564 5.679 6.447 9.17.659.594 1.406 1.266 2.183 1.983a.545.545 0 00.74 0c.777-.717 1.524-1.39 2.184-1.982C15.436 11.265 18 8.959 18 5.588 18 2.466 15.944.112 13.219.112z"
          fill="#fff"
        />
      </svg>),
  },
  {
    label: 'Feedback',
    path: '/feedback/',
    color: theme.colors.profileFeedback,
    icon: (
      <svg width={20} height={21} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.487.833h-3.64C3.933.833 0 2.393 0 8.68c0 2.293.433 7.54 7.243 7.834-.23 1.71-1.475 2.576-1.53 2.615a.566.566 0 00.314 1.037c2.845 0 5.116-2.777 5.754-3.643 4.631-.094 7.552-2.439 7.552-7.843 0-6.286-3.933-7.847-7.846-7.847z"
          fill="#fff"
        />
      </svg>),
  },
  {
    label: 'Settings',
    path: '/settings',
    color: theme.colors.alertPink,
    icon: (
      <svg width={22} height={21} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M21.51 8.72l-2.574-.603a7.721 7.721 0 00-.541-1.255L19.71 4.77a.591.591 0 00-.09-.75l-1.83-1.748a.665.665 0 00-.787-.086L14.81 3.441a8.528 8.528 0 00-1.315-.517L12.878.468A.657.657 0 0012.246 0H9.668a.657.657 0 00-.632.468l-.618 2.456a8.53 8.53 0 00-1.315.517L4.91 2.186a.664.664 0 00-.786.086L2.295 4.02a.591.591 0 00-.09.75l1.314 2.092c-.219.406-.4.824-.541 1.255L.49 8.72a.613.613 0 00-.49.59v2.461c0 .284.206.53.49.591l2.488.603c.142.43.322.85.541 1.255l-1.315 2.092a.591.591 0 00.09.75l1.831 1.748a.664.664 0 00.786.086l2.192-1.255c.425.209.863.381 1.315.517l.618 2.374c.078.271.335.468.632.468h2.578a.657.657 0 00.632-.468l.618-2.374a8.52 8.52 0 001.315-.517l2.192 1.255c.258.148.58.11.786-.086l1.83-1.747a.591.591 0 00.09-.751l-1.314-2.092c.22-.406.4-.824.541-1.255l2.574-.603a.613.613 0 00.49-.59V9.31a.613.613 0 00-.49-.591zm-10.553 4.897c-1.779 0-3.223-1.378-3.223-3.076 0-1.698 1.444-3.076 3.223-3.076s3.223 1.378 3.223 3.076c0 1.698-1.444 3.076-3.223 3.076z"
          fill="#fff"
        />
      </svg>),
  },
];

function Layout(props) {
  const dataContext = useContext(DataContext);
  const { isLoading } = dataContext;

  // User data
  const { userCoachData } = dataContext;
  const userName = userCoachData?.name;

  // Auth functions
  const { authSignOut } = dataContext;

  const { children } = props;

  if (isLoading) return <Loading visible={isLoading} />;

  return (
    <SLayout>
      <Header userName={userName} onLogout={authSignOut} />
      <MainMenu
        menuItems={mainMenuItems}
      />
      <main>{children}</main>
    </SLayout>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

const SLayout = styled.div`
    
`;
