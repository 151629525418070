import { Auth } from 'aws-amplify';

const signIn = async (email, password) => {
  try {
    const response = await Auth.signIn(email, password);
    if (response.attributes && response.attributes['custom:disabledByUser'] === 'True') {
      await Auth.signOut();
      throw new Error('User does not exist.');
    }
    if (response.attributes && response.attributes['custom:isActive'] === 'False') {
      await Auth.signOut();
      throw new Error('User is not actived.');
    }
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

const resendConfirmationCode = async (username) => {
  try {
    const response = await Auth.resendSignUp(username);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

const signUp = async (username, password, profileID) => {
  try {
    await Auth.signUp({
      username,
      password,
      attributes: {
        'custom:isActive': 'False',
        'custom:profile_id': profileID,
      },
    });
  } catch (error) {
    throw new Error(error.message);
  }
};

const confirmSignUp = async (email, code) => {
  try {
    const response = await Auth.confirmSignUp(email, code, {
      forceAliasCreation: true,
    });
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

const completeNewPassword = async (user, newPassword) => {
  try {
    const response = await Auth.completeNewPassword(user, newPassword);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

const signOut = async () => {
  try {
    const response = await Auth.signOut();
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

const checkAuth = async () => {
  try {
    const response = await Auth.currentAuthenticatedUser();
    const { attributes, signInUserSession } = response;
    return { attributes, jwtToken: signInUserSession.accessToken.jwtToken };
  } catch (error) {
    throw new Error(error.message);
  }
};

const changeUserPassword = async (oldPassword, newPassword) => {
  await Auth.currentAuthenticatedUser()
    .then((user) => Auth.changePassword(user, oldPassword, newPassword))
    .then((data) => (data)).catch((error) => {
      throw new Error(error.message);
    });
};

const forgotPassword = async (email) => {
  try {
    await Auth.forgotPassword(email)
      .then((data) => data).catch((error) => {
        throw new Error(error.message);
      });
  } catch (error) {
    throw new Error(error.message);
  }
};

const resetPassword = async (email, code, newPassword) => {
  try {
    await Auth.forgotPasswordSubmit(email, code, newPassword)
      .then((data) => data).catch((error) => {
        throw new Error(error.message);
      });
  } catch (error) {
    throw new Error(error.message);
  }
};

export {
  signIn,
  resendConfirmationCode,
  signOut,
  checkAuth,
  signUp,
  confirmSignUp,
  completeNewPassword,
  forgotPassword,
  resetPassword,
  changeUserPassword,
};
