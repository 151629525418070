export const getCoach = /* GraphQL */ `
  query GetCoach($id: ID!) {
    getCoach(id: $id) {
  id
  name
  teamID
  team {
    id
    name
    sport
    college {
      name
      country
      state
    }
  }
  favorites {
    items {
      id
      athleteID
    }
    nextToken
  }
  }
}
`;

export const createCoachMessage = /* GraphQL */ `
  mutation CreateCoachMessage(
    $input: CreateCoachMessageInput!
  ) {
    createCoachMessage(input: $input) {
      id
  }
}
`;
export const createCoachAthleteFavorites = /* GraphQL */ `
mutation CreateCoachAthleteFavorites(
  $input: CreateCoachAthleteFavoritesInput!
) {
  createCoachAthleteFavorites(input: $input) {
  id
  athleteID
  coachID
  }
}
`;
export const deleteCoachAthleteFavorites = /* GraphQL */ `
  mutation DeleteCoachAthleteFavorites(
    $input: DeleteCoachAthleteFavoritesInput!
  ) {
    deleteCoachAthleteFavorites(input: $input) {
      id
      athleteID
      coachID
  }
}
`;
export const createSearchTerm = /* GraphQL */ `
  mutation CreateSearchTerm(
    $input: CreateSearchTermInput!
  ) {
    createSearchTerm(input: $input) {
      id
  }
}
`;

export const disableCoachAccount = /* GraphQL */ `
  mutation UpdateCoach(
    $input: UpdateCoachInput!
    $condition: ModelCoachConditionInput
  ) {
    updateCoach(input: $input, condition: $condition) {
    disabledByUser
    deleteAppReason
    }
  }
`;

export const createCoach = /* GraphQL */ `
  mutation CreateCoach(
    $input: CreateCoachInput!
    $condition: ModelCoachConditionInput
  ) {
    createCoach(input: $input, condition: $condition) {
      id
      name
      profile_pic
      birthdate
      gender
      contact_email
      contact_phone
      disabledByUser
      role
      teamID
    }
  }
`;

export const updateCoach = /* GraphQL */ `
  mutation UpdateCoach(
    $input: UpdateCoachInput!
    $condition: ModelCoachConditionInput
  ) {
    updateCoach(input: $input, condition: $condition) {
      id
      disabledByUser
      deleteAppReason
    }
  }
`;
