import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import theme from '../../styles/theme.styles';
import { phoneSplitToCountryCodeAndNumber } from '../../helpers';
import { getImageAtSize } from '../../helpers/cloudinaryHelpers';

function ListItem({ agency, path }) {
  const {
    name,
    logo,
    country,
    contact_phone,
    athleteCount,
  } = agency;
  const phone = phoneSplitToCountryCodeAndNumber(contact_phone);
  const imageLogo = getImageAtSize({
    url: logo, w: 143, h: 83, o: { cropMode: 'fit' },
  });
  return (
    <ListItemContainer>
      <SListItem to={path}>
        <BlockAvatar>
          <Avatar>
            <AvatarImage image={imageLogo} />
          </Avatar>
        </BlockAvatar>
        <Texts>
          <HeaderLine><strong>{name}</strong></HeaderLine>
          <TextLine>
            {country.join(' | ')}
          </TextLine>
          {
            (phone) ? (
              <TextLine>
                (+
                {phone.countryCode}
                )
                {phone.number}
              </TextLine>
            ) : null
          }
          <AthleteCount>
            <svg width={13} height={14} fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9.625 3.5a1.75 1.75 0 1 0 0-3.5 1.75 1.75 0 0 0 0 3.5ZM2.625 3.5a1.75 1.75 0 1 0 0-3.5 1.75 1.75 0 0 0 0 3.5ZM12.247 9.144l-.398-3.98a.872.872 0 0 0-.87-.789H8.271a.872.872 0 0 0-.87.787l-.399 3.982a.44.44 0 0 0 .435.481h.479l.358 3.588c.045.448.42.787.872.787h.958c.452 0 .826-.34.87-.787l.36-3.588h.478a.435.435 0 0 0 .435-.481ZM5.247 9.144l-.398-3.98a.872.872 0 0 0-.87-.789H1.271a.872.872 0 0 0-.87.787L.001 9.145a.44.44 0 0 0 .435.481h.479l.358 3.588c.045.448.42.787.872.787h.958c.452 0 .826-.34.87-.787l.36-3.588h.478a.44.44 0 0 0 .435-.481Z"
                fill="#fff"
              />
            </svg>
            {athleteCount}
          </AthleteCount>
        </Texts>
      </SListItem>
    </ListItemContainer>
  );
}

ListItem.propTypes = {
  athlete: PropTypes.object,
};

ListItem.defaultProps = {
  athlete: {},
};

export default ListItem;

const ListItemContainer = styled.div`
    position: relative;
    margin-bottom: 16px;
`;
const SListItem = styled(NavLink)`
    display: flex;
    padding: 12px;
    border-radius: 13px;
    color: ${theme.colors.white};
    text-decoration: none;
    background-color: ${theme.colors.transparentWhite2};
`;
const BlockAvatar = styled.div`
    position: relative;
    width: 173px;
    height: 113px;
`;

const Avatar = styled.div`
    position: relative;
    width: 173px;
    height: 113px;
    border-radius: 10px;
    background-color: white;
`;
const AvatarImage = styled.div`
    position: absolute;
    top: 15px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    border-radius: 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(${(props) => props.image});
`;

const Texts = styled.div`
    flex: 1;
    margin-left: 14px;

`;

const HeaderLine = styled.div`
    font-size: 21px;
    line-height: 30px;
    font-weight: 400;
    margin: 6px 52px 2px 0;
    & strong{
        font-size: 25px;
        font-weight: 700;
        margin-right: 6px;
    }

`;
const TextLine = styled.div`
    font-size: 13px;
    line-height: 26px;
    font-weight: 400;
    svg{
        vertical-align: middle;
        margin-right: 8px;
    }
`;
const AthleteCount = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 8px 10px;
    background-color: rgba(255,255,255,0.1);
    border-bottom-right-radius: 10px;
    border-top-left-radius: 10px;
    font-size: 14px;
    font-weight: 400;
    & svg{
        vertical-align: middle;
        margin-right: 8px;
    }
`;
