import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik, Field } from 'formik';
import validator from 'validator';
import InputField from '../../components/_FormElements/InputField';
import Stepper from '../../components/_FormElements/Stepper';
import InputCheckbox from '../../components/_FormElements/InputCheckBox';
import {
  FormLayout,
  HeaderTitle,
  HeaderInfoText,
  Form,
  Button,
  ErrorText,
} from './OnBoarding.style';

function PhoneStep({
  setStep,
  step,
  data,
  setData,
}) {
  const [phone, setPhone] = useState(null);

  // const {data, setData} = useContext(SignUpContext)
  const onChangeValueFormik = ({ form, fieldName, value }) => {
    form.setFieldValue(fieldName, value);
  };
  function InputCheckboxFieldAcceptWhatsApp({
    field, colors, form, ...props
  }) {
    const { values, errors } = form;
    return (
      <InputCheckbox data={data} value={values.whatsapp} fieldName="whatsapp" formikForm={form} onChangeValueFormik={onChangeValueFormik} remainme>
        <span>I would like to receive updates on WhatsApp</span>
      </InputCheckbox>
    );
  }

  return (
    <FormLayout>
      <HeaderTitle>Phone Number</HeaderTitle>
      <HeaderInfoText>Can you please provide your contact for future communications?</HeaderInfoText>
      <Formik
        initialValues={{ phone: data.phone || '' }}
        onSubmit={(values, { setSubmitting }) => {
          const { phone, whatsapp } = values;
          setData({ ...data, phone, whatsapp });
          setSubmitting(false);
          setStep(step + 1);
        }}
        validate={(values) => {
          const errors = {};
          if (!validator.isMobilePhone(values.phone, 'any', { strictMode: true })) errors.phone = 'Invalid phone number';

          return errors;
        }}
      >
        {({
          handleChange, handleBlur, handleSubmit, errors, values, isSubmitting, touched,
        }) => (
          <Form onSubmit={handleSubmit}>
            <InputField
              placeholder="+15417543010"
              value={values.phone}
              onBlur={handleBlur('phone')}
              onChange={handleChange('phone')}
            />
            {touched.phone && errors.phone && (<ErrorTextLeft>{errors.phone}</ErrorTextLeft>)}
            <Field name="whatsapp" component={InputCheckboxFieldAcceptWhatsApp} />
            <Button type="submit" disabled={isSubmitting} step={step}>Next</Button>
            <Stepper active={step - 1} />
          </Form>
        )}
      </Formik>
    </FormLayout>
  );
}

const ErrorTextLeft = styled(ErrorText)`
    text-align: left;
`;
export default PhoneStep;
