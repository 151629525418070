/* eslint-disable import/prefer-default-export */
export const listAgents = /* GraphQL */ `
  query ListAgents(
    $filter: ModelAgentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAgents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        agencyID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
