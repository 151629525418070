import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from '../../styles/theme.styles';

function InputCheckBox(allProps) {
  const {
    value = false,
    children = null,
    fieldName,
    formikForm,
    onChangeValueFormik,
    disabled = null,
    data = null,
    remainme = null,
    textAlign = 'left',
  } = allProps;

  const [isVisible, setVisible] = useState(value);

  useEffect(() => {
    remainme && setVisible(data[fieldName]);
  }, []);

  useEffect(() => {
    onChangeValueFormik({ form: formikForm, fieldName, value: isVisible });
  }, [isVisible]);

  const handleCheckbox = () => {
    !disabled && setVisible(!isVisible);
  };

  return (
    <InputFieldCheckbox>
      <InputFieldContainer textAlign={textAlign}>
        <InputFieldButton onClick={handleCheckbox}>
          <InputFieldButtonInside isVisible={isVisible} />
        </InputFieldButton>
        <InputLabel>{children}</InputLabel>
      </InputFieldContainer>
    </InputFieldCheckbox>
  );
}

const InputFieldCheckbox = styled.div`
  backgroud-color: ${theme.colors.white};
    width: 100%;
`;
const InputFieldContainer = styled.div`
  flex-direction: row;
  padding: 16px 0px;
    text-align: ${(props) => props.textAlign};
`;
const InputLabel = styled.span`
  font:  ${theme.fonts.gotham15Regular};
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  color: ${theme.colors.white};
    display: inline-block;
    vertical-align: middle;
`;
const InputFieldButton = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  border: 1px solid #fff;
    display: inline-block;
    vertical-align: middle;
`;
const InputFieldButtonInside = styled.div`
  width: 8px;
  height: 8px;
  margin-top: 3px;
  margin-left: 3px;
  ${(props) => props.isVisible && 'background-color: #fff;'}
`;
export default InputCheckBox;
