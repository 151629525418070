const settingButtonTypes = {
  BASIC: 'BASIC',
  SWITCH: 'SWITCH',
  SHARE: 'SHARE',
  LOGOUT: 'LOGOUT',
  DELETE: 'DELETE',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  LEGAL: 'LEGAL',
};

export default settingButtonTypes;
