export const listAgencys = /* GraphQL */ `
  query ListAgencys(
    $filter: ModelAgencyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAgencys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        logo
        country
        contact_email
        contact_phone
        website
        agents {
          items {
            id
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const getAgency = /* GraphQL */ `
  query GetAgency($id: ID!) {
    getAgency(id: $id) {
      id
      name
      logo
      country
      contact_email
      contact_phone
      website
      agents {
        items {
          id
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
