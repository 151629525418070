import React, { useState } from 'react';
import styled from 'styled-components';
import theme from '../../styles/theme.styles';

function InputField(allProps) {
  const {
    value = '',
    placeholder = '',
    label = null,
    isPassword = false,
  } = allProps;

  const [text, setText] = useState(value);
  const [passwordVisible, setPasswordVisible] = useState(false);

  return (
    <InputFieldContainer>
      {label ? <InputLabel>{label}</InputLabel> : null}
      <InputFieldDefault>
        {
  isPassword ? (
    <>
      <Value
        {...allProps}
        placeholderTextColor="rgba(255, 255, 255, 0.5)"
        placeholder={placeholder}
        valueDefault={text}
        type={passwordVisible ? 'text' : 'password'}
      />
      <Eye onClick={() => setPasswordVisible(!passwordVisible)}>
        {
          !passwordVisible ? (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z" stroke="white" strokeOpacity="0.8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="white" strokeOpacity="0.8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          ) : (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z" stroke="white" strokeOpacity="0.8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="white" strokeOpacity="0.8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <rect x="4.26834" y="2.84819" width="23.265" height="2" transform="rotate(43.8766 4.26834 2.84819)" fill="white" />
            </svg>
          )
        }
      </Eye>
    </>
  ) : (
    <Value
      {...allProps}
      placeholderTextColor="rgba(255, 255, 255, 0.5)"
      placeholder={placeholder}
      valueDefault={text}
    />
  )
  }

      </InputFieldDefault>
    </InputFieldContainer>
  );
}

export default InputField;

const Eye = styled.div`
  position: absolute;
  right: 14px;
  top: 9px; 
  cursor: pointer;
`;
const InputFieldContainer = styled.div`
  width: 100%;
  margin: 5px 0;
  text-align: left;
`;
const InputLabel = styled.label`
  font:  ${theme.fonts.gotham15Regular};
  font-size: 13px;
  font-weight: 400;
  color: ${theme.colors.white};
  width: 100%;
  min-height: 20px;
  line-height: 26px;
`;
const InputFieldDefault = styled.div`
  background-color: ${theme.colors.white05};
  border-radius: 4px;
  width: 100%;
  padding: 0 15px;
  position: relative;
  border: 1px solid ${theme.colors.white};
  z-index: 1;
  margin: 0;
  padding: 0;
`;
const Value = styled.input`
  font: ${theme.fonts.gotham15Regular};
  font-size: 13px;
  font-weight: 400;
  color: ${theme.colors.white};
  position: relative;
  width: 100%;
  min-height: 40px;
  padding: 0 15px;
  border-radius: 4px; 
  background: rgba(255, 255, 255, 0.05);
  border: 0;
`;
