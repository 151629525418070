import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import theme from '../../styles/theme.styles';
import TextArea from './TextArea';
import Button from './Button';

function TextAreaForm({
  generalLabel, placeholder, cancelLabel, onCancel, submitLabel, onSubmit, sentReponse, initialFormState,
}) {
  const [formState, setFormState] = useState(initialFormState); // sending, error, sent

  return (
    <Container>
      <Formik
        initialValues={{ message: '' }}
        validate={(values) => {
          const errors = {};
          if (!values.message) {
            errors.message = true;
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setFormState('sending');
          try {
            const sending = await onSubmit(values, setSubmitting);
            if (sending) {
              setFormState('sent');
            } else {
              setFormState('error');
            }
          } catch (e) {
            setFormState('error');
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            { formState === 'notSend'
              && (
              <>
                { generalLabel !== '' && <GeneralLabel>{generalLabel}</GeneralLabel> }
                <TextArea
                  name="message"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.message}
                  error={errors.message && touched.message}
                  placeholder={placeholder}
                />
                <Actions>
                  { onCancel !== null && <Button style={2} label={cancelLabel} onClick={onCancel} /> }
                  <Button tag="button" buttonType="submit" style={1} label={submitLabel} disabled={isSubmitting} />
                </Actions>
              </>
              )}
            { formState === 'sending'
              && (
              <Sending>
                Sending...
              </Sending>
              )}
            { formState === 'error'
              && (
              <Error>
                <strong>Wow. This is embarrassing.</strong>
                <br />
                The form could not be submitted. Try to contact us by email and we will be happy to answer any questions.
                <br />
                <br />
                Sorry for the inconvenience.
              </Error>
              )}
            { formState === 'sent'
              && (
              <Sent>
                {sentReponse}
              </Sent>
              )}
          </form>
        )}
      </Formik>
    </Container>
  );
}

TextAreaForm.propTypes = {
  generalLabel: PropTypes.string,
  placeholder: PropTypes.string,
  cancelLabel: PropTypes.string,
  onCancel: PropTypes.func,
  submitLabel: PropTypes.string,
  onSubmit: PropTypes.func,
  sentReponse: PropTypes.string,
  initialFormState: PropTypes.string,
};

TextAreaForm.defaultProps = {
  generalLabel: '',
  placeholder: '',
  cancelLabel: 'Cancel',
  onCancel: null,
  submitLabel: 'Send',
  onSubmit: null,
  sentReponse: 'Form sent successfully',
  initialFormState: 'notSend',
};

export default TextAreaForm;

const Container = styled.div`
  position: relative;
  display: block;
`;
const GeneralLabel = styled.div`
padding: 32px 0 16px;

`;
const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 32px 0 64px;
  font-size: 0;
  & > *:first-child:nth-last-child(1) {
    width: 100%;
  }
  & > *:first-child:nth-last-child(2),
  & > *:first-child:nth-last-child(2) ~ * {
    width: calc(50% - 8px);
  }
`;
const Sending = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 140px;
  background-color: ${theme.colors.transparentWhite2};
  border-radius: 20px;
  padding: 12px;
`;
const Sent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 140px;
  color: ${theme.colors.royalBlue};
  background-color: ${theme.colors.white};
  border-radius: 20px;
  padding: 12px;
`;
const Error = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  min-height: 140px;
  padding: 40px 0;
  color: ${theme.colors.alertPink};
  background-color: ${theme.colors.white};
  border-radius: 20px;
  padding: 12px;
  & strong{
    font-weight: 700;
  }
`;
