import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { find } from 'lodash';
import { useParams, NavLink, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import ContentWrapper from '../../components/_Layout/ContentWrapper';
import { DataContext } from '../../contexts/dataContext';
import theme from '../../styles/theme.styles';

// AthleteInfo
import AthleteInfo from '../../components/Athletes/AthleteInfo';

function AthleteView() {
  const params = useParams();
  const location = useLocation();
  const isInSearchAthletePage = () => location.pathname.substr(0, 13) === '/app/athlete_';
  const isInFavoritesAthletePage = () => location.pathname.substr(0, 19) === '/favorites/athlete_';
  const { athleteId } = params;
  const dataContext = useContext(DataContext);
  const [athleteData, setAthleteData] = useState(null);

  const backRoute = isInSearchAthletePage()
    ? {
      url: '/app/',
      title: 'Athletes',
    }
    : isInFavoritesAthletePage
      ? {
        url: '/favorites/',
        title: 'Favorites',
      }
      : {
        url: '',
        title: '',
      };

  useEffect(() => {
    const athlete = find(dataContext.athletes, { id: athleteId });
    if (athlete) {
      setAthleteData(athlete);
    }
  }, [dataContext.athletes]);

  return (
    <AthleteContainer>
      {athleteData && (
        <motion.div
          initial={{
            y: '100vh',
          }}
          animate={{
            y: '0vh',
            transition: {
              duration: 0.3,
            },
          }}
          exit={{
            y: '100vh',
            transition: {
              duration: 0.3,
            },
          }}
        >
          <ContentWrapper>
            <AthleteContent1>
              <Header>
                <TitleNav to={backRoute.url}>
                  <svg
                    width={8}
                    height={12}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.035 11.995c-.29 0-.523-.1-.723-.29L.383 6.831C.125 6.574.008 6.317 0 6.002c0-.316.125-.59.374-.83L5.312.298c.2-.2.44-.299.723-.299.572 0 1.037.465 1.037 1.038 0 .282-.124.547-.332.755L2.457 6.001 6.74 10.21c.216.207.332.465.332.755 0 .565-.465 1.03-1.037 1.03Z"
                      fill="#fff"
                    />
                  </svg>
                  {backRoute.title}
                </TitleNav>
              </Header>
              <AthleteContent2>
                <AthleteLayout>
                  <HeaderSpacer />

                  <LayoutContent>
                    <AthleteInfo
                      favoriteToggle={dataContext.favoriteToggle}
                      athlete={athleteData}
                    />
                  </LayoutContent>
                </AthleteLayout>
              </AthleteContent2>
            </AthleteContent1>
          </ContentWrapper>
        </motion.div>
      )}
    </AthleteContainer>
  );
}

AthleteView.propTypes = {};

AthleteView.defaultProps = {};

export default AthleteView;

const AthleteLayout = styled.div``;
const AthleteContainer = styled.div`
  z-index: 1000;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 0;
`;
const AthleteContent1 = styled.div`
  position: relative;
  width: 100%;
  height: 0;
`;
const AthleteContent2 = styled.div`
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  background-color: ${theme.colors.brandDark};
  scrollbar-color: inherit transparent;
  scrollbar-width: thin;
  padding-right: 10px;
  ::-webkit-scrollbar {
    width: 20px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.tennisBall};
    border: 5px solid transparent;
    border-radius: 18px;
    background-clip: padding-box;  
    width: 5px;
  }
`;
const HeaderSpacer = styled.div`
  height: ${theme.sizes.header.heightBig.tabletPortrait}px;
  @media ${theme.mq.desktop} {
    height: ${theme.sizes.header.heightBig.desktop}px;
  }
`;
const Header = styled.div`
  position: absolute;
  width: 100% ;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  height: ${theme.sizes.header.heightBig.tabletPortrait}px;
  top: 0;
  background: ${theme.colors.brandDark};
  background: linear-gradient(180deg, rgb(51,152,203,1) 0%, rgb(51,152,203,1) 89%, rgb(51,152,203,0) 100%);
  z-index: 10;
  @media ${theme.mq.desktop} {
    height: ${theme.sizes.header.heightBig.desktop}px;
  }
`;
const TitleNav = styled(NavLink)`
  margin: 30px 0 23px 0;
  font-size: 21px;
  font-weight: 700;
  line-height: 41px;
  letter-spacing: 0.374px;
  text-decoration: none;
  color: white;
  & svg {
    margin-right: 12px;
  }
`;
const LayoutContent = styled.div`
  background-color: ${theme.colors.transparentWhite2};
  border-radius: 20px;
  padding: 12px;
`;
