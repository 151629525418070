import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { API, Auth } from 'aws-amplify';
import { confirmAlert } from 'react-confirm-alert'; // Import
import ContentWrapper from '../../components/_Layout/ContentWrapper';
import Layout from '../../components/_Layout/Layout';
import { DataContext } from '../../contexts/dataContext';
import theme from '../../styles/theme.styles';
// filters
import RightColumn from '../../components/_Layout/RightColumn';
import Popup from '../../components/_FormElements/Popup';

import { removeUserStoredData } from '../../store/authStore';

import { signOut } from '../../services/authService';

import { disableCoachAccount } from '../../custom_graphql_queries/coachs';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

function DeleteAccount() {
  const navigate = useNavigate();

  const [errorTextValue, setErrorTextValue] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [deleteAppReason, setDeleteAppReason] = useState('');

  const dataContext = useContext(DataContext);

  useEffect(() => {
    setIsOpen(false);
  }, []);

  const handleDeleteAccountConfirmed = async () => {
    const query = {
      query: disableCoachAccount,
      variables: {
        input: {
          id: dataContext.userCoachData.id,
          deleteAppReason,
          disabledByUser: true,
        },
      },
    };
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, {
        'custom:disabledByUser': 'True',
      });
      await API.graphql(query);
      await signOut().then(() => {
        removeUserStoredData();
      });
    } catch (e) {
      setErrorTextValue(e);
    }
  };

  return (
    <Layout>
      <RightColumn />
      <ContentWrapper>
        <FeedBackLayout>
          <HeaderSpacer />
          <Header>
            <Title>Delete your Alliance Athletes account</Title>
          </Header>
          <HeaderLine>
            Please tell us the reason why you want to delete your account. It
            will help us to keep improving
          </HeaderLine>
          {isOpen && (
            <Popup
              isOpen={isOpen}
              content={(
                <>
                  <HeaderLine>Successful</HeaderLine>
                  <Label>Your password has been changed successfully</Label>
                </>
              )}
              handleClose={() => setIsOpen(!isOpen)}
            />
          )}
          <div>
            <Formik
              initialValues={{
                old_password: '',
                new_password: '',
                password_confirmation: '',
              }}
              validate={() => {
                const errors = {};

                return errors;
              }}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                confirmAlert({
                  overlayClassName: 'overlay-confirm-modal',
                  customUI: ({ onClose }) => (
                    <CustomModalUI>
                      <ModalTitle>Please confirm!</ModalTitle>
                      <ModalSubTitle>
                        Are you absolutely sure you want to delete your
                        account? This cannot be undone.
                      </ModalSubTitle>
                      <ModalButtonsContainer>
                        <StyledButton onClick={onClose}>No</StyledButton>
                        <StyledButton
                          onClick={() => {
                            setTimeout(() => {
                              handleDeleteAccountConfirmed(
                                values,
                                resetForm,
                              ).then(() => {
                                onClose();
                                setSubmitting(false);
                              });
                            }, 400);
                          }}
                        >
                          Yes
                        </StyledButton>
                      </ModalButtonsContainer>
                    </CustomModalUI>
                  ),
                });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <ErrorText>{errorTextValue}</ErrorText>
                  <InputFieldDefault>
                    <InputFieldTextArea
                      placeholder="Why are you deleting your account?"
                      value={values.message}
                      onChange={(e) => {
                        handleChange('message');
                        setDeleteAppReason(e.target.value);
                      }}
                      onBlur={handleBlur('message')}
                    />
                  </InputFieldDefault>
                  <ErrorText>
                    {errors.message && touched.message && errors.message}
                  </ErrorText>
                  <Actions>
                    <Button onClick={() => navigate('/settings')}>
                      Cancel
                    </Button>
                    <Button type="submit">Delete account</Button>
                  </Actions>
                </form>
              )}
            </Formik>
          </div>
        </FeedBackLayout>
      </ContentWrapper>
    </Layout>
  );
}

export default DeleteAccount;

const FeedBackLayout = styled.div`
  position: relative;
`;

const Label = styled.label`
  display: block;
  margin: 24px 0 8px;
  font-size: 13px;
`;
const InputFieldDefault = styled.div`
  background-color: ${theme.colors.white05};
  border-radius: 4px;
  width: 100%;
  padding: 0 15px;
  position: relative;
  border: 1px solid ${theme.colors.white};
  z-index: 1;
  margin: 0;
  padding: 0;
`;
const InputFieldTextArea = styled.textarea`
  min-height: 100px;
  font: ${theme.fonts.gotham15Regular};
  font-size: 13px;
  font-weight: 400;
  color: ${theme.colors.white};
  position: relative;
  width: 100%;
  padding: 10px 15px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.05);
  border: 0;
  resize: none;
  width: 100%;
  ::placeholder {
    color: ${theme.colors.transparentWhite6}
  }
`;
const Actions = styled.div`
  margin-top: 24px;
`;
const HeaderSpacer = styled.div`
  height: ${theme.sizes.header.heightBig.tabletPortrait}px;
  @media ${theme.mq.desktop} { 
    height: height: ${theme.sizes.header.heightBig.desktop}px;
  }
`;
const Header = styled.div`
  position: fixed;
  width: calc(
    100% - ${theme.sizes.columns.col1.tabletPortrait}px -
      ${theme.sizes.columns.col2.tabletPortrait}px
  );
  height: ${theme.sizes.header.heightBig.tabletPortrait}px;
  top: 0;
  background: ${theme.colors.brandDark};
  background: linear-gradient(180deg, rgb(51,152,203,1) 0%, rgb(51,152,203,1) 89%, rgb(51,152,203,0) 100%);
  z-index: 1;
  @media ${theme.mq.desktop} {
    height: ${theme.sizes.header.heightBig.desktop}px;
    width: calc(
      100% - ${theme.sizes.columns.col1.desktop}px -
        ${theme.sizes.columns.col2.desktop}px
    );
  }
`;
const Title = styled.h1`
  margin: 30px 0 23px 0;
  font-size: 32px;
  font-weight: 700;
  line-height: 41px;
  letter-spacing: 0.374px;
`;
const HeaderLine = styled.div`
  font-size: 15px;
  line-height: 30px;
  font-weight: 700;
  margin: 6px 52px 12px 0;
  & strong {
    font-size: 25px;
    font-weight: 700;
    margin-right: 6px;
  }
`;
const ErrorText = styled.p`
  display: inline;
  font-size: 13px;
  font-weight: 700;
  font-family: ${theme.fonts.gotham13Regular};
  text-decoration: none;
  color: red;
  line-height: 30px;
`;
const Button = styled.button`
  display: flex-inline;
  width: 49%;
  border-radius: 8px;
  border-width: 0px;
  cursor: pointer;
  font-family: ${theme.fonts.family1};
  font-size: 17px;
  font-weight: 700;
  line-height: 48px;
  min-height: 48px;
  text-align: center;
  background-color: ${theme.colors.tennisBall};
  color: ${theme.colors.black};
  &:last-child {
    float: right;
  }
  ${(props) => props.disabled
    && `
       opacity: 0.4;
   `}
`;
const ModalTitle = styled.h1`
  margin: 10px 0 23px 0;
  font-size: 32px;
  font-weight: 700;
  line-height: 41px;
  letter-spacing: 0.374px;
`;
const ModalSubTitle = styled.p`
  margin: 10px 0;
  font-size: 13px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0.374px;
`;
const StyledButton = styled.div`
  width: 160px;
  padding: 10px;
  border: 1px solid #fff;
  margin: 10px;
  cursor: pointer;
  background: none;
  color: #fff;
  font-size: 14px;
  &:hover {
    background-color: ${theme.colors.transparentWhite2};
    border-color: ${theme.colors.white};
  }
`;
const ModalButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 55px;
  margin-top: 20px;
`;
const CustomModalUI = styled.div`
  text-align: center;
  width: 500px;
  padding: 40px;
  background: ${theme.colors.royalBlue};
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: #fff;
  z-index: 9999;
`;
