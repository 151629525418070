import React, { useContext } from 'react';
import styled from 'styled-components';
import { Outlet } from 'react-router-dom';

import { AnimatePresence } from 'framer-motion';
import ContentWrapper from '../../components/_Layout/ContentWrapper';
import Layout from '../../components/_Layout/Layout';
import { DataContext } from '../../contexts/dataContext';
import ListItemAgency from '../../components/Agencies/ListItemAgency';
import theme from '../../styles/theme.styles';
// filters
import RightColumn from '../../components/_Layout/RightColumn';

function Agencies() {
  const dataContext = useContext(DataContext);
  const { agencies } = dataContext;

  return (
    <Layout>
      <RightColumn />
      <ContentWrapper>
        <AgenciesLayout>
          <Header>
            <Title>Agencies</Title>
          </Header>
          <ListScroll>
            <HeaderSpacer />
            {

              agencies.map((agency) => (
                <ListItemAgency agency={agency} key={agency.id} path={`/agencies/agency_${agency.id}`} />
              ))
            }
          </ListScroll>

        </AgenciesLayout>

      </ContentWrapper>
      <AnimatePresence>
        <Outlet />
      </AnimatePresence>
    </Layout>
  );
}

Agencies.propTypes = {};

Agencies.defaultProps = {};

export default Agencies;

const AgenciesLayout = styled.div`
  position: relative;
`;

const HeaderSpacer = styled.div`
  height: ${theme.sizes.header.heightBig.tabletPortrait}px;
  @media ${theme.mq.desktop} { 
    height: height: ${theme.sizes.header.heightBig.desktop}px;
  }
`;
const Header = styled.div`
  position: absolute;
  display: flex;
  align-items: flex-end;
  height: ${theme.sizes.header.heightBig.tabletPortrait}px;
  width: 100% ;
  top: 0;
  left: 0;
  background: ${theme.colors.brandDark};
  background: linear-gradient(180deg, rgb(51,152,203,1) 0%, rgb(51,152,203,1) 89%, rgb(51,152,203,0) 100%);
  z-index: 2;
  @media ${theme.mq.desktop} { 
    height: ${theme.sizes.header.heightBig.desktop}px;
  }
`;
const Title = styled.h1`
  margin: 30px 0 23px 0;
  font-size: 32px;
  font-weight: 700;
  line-height: 41px;
  letter-spacing: 0.374px;
`;

const ListScroll = styled.div`
  position: absolute;
  overflow: auto;
  width: 100% ;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
  scrollbar-color: inherit transparent;
  scrollbar-width: thin;
  ::-webkit-scrollbar {
    width: 20px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.tennisBall};
    border: 5px solid transparent;
    border-radius: 18px;
    background-clip: padding-box;  
    width: 5px;
  }
`;
