import React, { useContext } from 'react';
import styled from 'styled-components';
import TextAreaForm from '../../components/_FormElements/TextAreaForm';

import ContentWrapper from '../../components/_Layout/ContentWrapper';
import Layout from '../../components/_Layout/Layout';
import { DataContext } from '../../contexts/dataContext';
import theme from '../../styles/theme.styles';

// filters
import RightColumn from '../../components/_Layout/RightColumn';

function FeedBack() {
  const dataContext = useContext(DataContext);

  const onFeedbackFormSubmit = async (values) => {
    try {
      const execDataContentFunction = await dataContext.feedBackFormSend({
        message: values.message,
      });
      if (execDataContentFunction) {
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  return (
    <Layout>
      <RightColumn />
      <ContentWrapper>
        <FeedBackLayout>
          <HeaderSpacer />
          <Header>
            <Title>Feedback</Title>
          </Header>
          <HeaderLine>Your feedback make us better...</HeaderLine>
          <TextAreaForm
            onSubmit={onFeedbackFormSubmit}
            placeholder="I think..."
            submitLabel="Send"
            sentReponse="Thanks for your help!"
          />
        </FeedBackLayout>
      </ContentWrapper>
    </Layout>
  );
}

export default FeedBack;

const FeedBackLayout = styled.div`
  position: relative;
`;

const HeaderSpacer = styled.div`
  height: ${theme.sizes.header.heightBig.tabletPortrait}px;
  @media ${theme.mq.desktop} { 
    height: height: ${theme.sizes.header.heightBig.desktop}px;
  }
`;
const Header = styled.div`
  position: fixed;
  width: calc(
    100% - ${theme.sizes.columns.col1.tabletPortrait}px -
      ${theme.sizes.columns.col2.tabletPortrait}px
  );
  height: ${theme.sizes.header.heightBig.tabletPortrait}px;
  top: 0;
  background: ${theme.colors.brandDark};
  background: linear-gradient(180deg, rgb(51,152,203,1) 0%, rgb(51,152,203,1) 89%, rgb(51,152,203,0) 100%);
  z-index: 1;
  @media ${theme.mq.desktop} {
    height: ${theme.sizes.header.heightBig.desktop}px;
    width: calc(
      100% - ${theme.sizes.columns.col1.desktop}px -
        ${theme.sizes.columns.col2.desktop}px
    );
  }
`;
const Title = styled.h1`
  margin: 30px 0 23px 0;
  font-size: 32px;
  font-weight: 700;
  line-height: 41px;
  letter-spacing: 0.374px;
`;
const HeaderLine = styled.div`
  font-size: 21px;
  line-height: 30px;
  font-weight: 700;
  margin: 6px 52px 12px 0;
  & strong {
    font-size: 25px;
    font-weight: 700;
    margin-right: 6px;
  }
`;
