export const listAthletes = /* GraphQL */ `
  query ListAthletes(
    $filter: ModelAthleteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAthletes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        agencyID
        agentID
        isVisible
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          DominantHand
        }
        featured_video {
          url
          metadata
        }
        other_videos {
          url
          metadata
        }
        level_of_competition
        showcase_participation {
          showcase_name
          city
          country
          date
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        facebook_url
        twitter_user
        linkedin_url
        sports_background
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          createdAt
          updatedAt
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agencyID
        }
        favorited {
          nextToken
        }
        enquiries {
          nextToken
        }
        tags {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const createAthleteEnquiry = /* GraphQL */ `
  mutation CreateAthleteEnquiry(
    $input: CreateAthleteEnquiryInput!
  ) {
    createAthleteEnquiry(input: $input) {
      id
  }
}
`;
