import React, { useEffect } from 'react';
import styled from 'styled-components';
import ReactSelect from 'react-select';
import theme from '../../styles/theme.styles';

function InputSelect(allProps) {
  const {
    label = null,
    division,
    setDivision,
    options,
    formikForm,
    fieldName,
    onChangeValueFormik,
    data,
  } = allProps;

  useEffect(() => {
    // division &&
    onChangeValueFormik({ form: formikForm, fieldName, value: division });
  }, [division]);

  const setValue = (options) => {
    const selected_division = options.filter((option) => option.value === data.division);
    setDivision(selected_division);
  };

  return (
    <InputFieldContainer>
      {label ? <InputLabel>{label}</InputLabel> : null}
      <CustomSelect
        value={
          (data.division && !division) ? setValue(options) : division
        }
        // eslint-disable-next-line no-use-before-define
        styles={customStyles}
        classNamePrefix="react-select"
        placeholder="Select your division"
        onChange={(selectedOption) => {
          setDivision(selectedOption);
        }}
        isSearchable={false}
        options={options}
        name="division"
        isLoading={false}
      />

    </InputFieldContainer>
  );
}

export default InputSelect;

const customStyles = {
  container: (provided) => ({
    ...provided,
    width: '100%',
    margin: '5px 0px',
  }),
};
export const InputFieldContainer = styled.div`
  width: 100%;
    text-align: left;
`;
export const InputLabel = styled.span`
  font:  ${theme.fonts.gotham15Regular};
  font-size: 15px;
  font-weight: 400;
  color: ${theme.colors.white};
  width: 100%;
  min-height: 20px;
`;

const CustomSelect = styled(ReactSelect)`
  font:  ${theme.fonts.gotham15Regular};
  font-size: 15px;
  font-weight: 400;
  color: ${theme.colors.white};
  margin-bottom: 200px;
  .react-select__input {
    backgroud-color: ${theme.colors.brandDark};
    font-size: 15px;
    font-weight: 400;
    color: ${theme.colors.white};
  }

  .react-select__option {
    color: ${theme.colors.brandDark};
  }
  .react-select__control {
    width: 100%;
    height: 45px;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 0 15px;
    font-size: 15px;
    color: #333;
    background: rgba(255, 255, 255, 0.05);
    font:  ${theme.fonts.gotham15Regular};
    font-size: 15px;
    font-weight: 400;
    color: ${theme.colors.white};
  }

  .react-select__single-value {
    color: ${theme.colors.white};
  } 
`;
