import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { useNavigate, NavLink } from 'react-router-dom';
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';
import theme from '../../styles/theme.styles';
import InputField from '../../components/_FormElements/InputField';
import LogoLogin from '../../components/assets/LogoLogin.svg.js';

// import {
// 	useAuthDispatch,
// } from "../../contexts/authContext"
// import { signIn, completeNewPassword } from '../../../src/services/authService'
import { DataContext } from '../../contexts/dataContext';

const initialFormState = {
  username: '',
  password: '',
  email: '',
  authCode: '',
  formType: 'SignIn',
};

export default function LoginScreen(props) {
  const navigate = useNavigate();

  const dataContext = useContext(DataContext);
  const {
    authSignIn, authSignOut, user, updateUser,
  } = dataContext;
  const [errorTextValue, setErrorTextValue] = useState(null);

  if (user !== 'notLoggedIn') {
    navigate('/app/', { replace: true });
  }

  return (
    <LoginScreenLayout>
      <LoginScreenLayoutContent>
        <LogoColumn>
          <LogoLogin />
        </LogoColumn>
        <LoginColumn>
          <div>
            <Formik
              initialValues={{ email: '', password: '' }}
              validate={(values) => {
                const errors = {};
                if (!values.password) {
                  errors.password = 'Required';
                }
                if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = 'Invalid email address';
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  authSignIn(values.email, values.password).then((user) => {
                    updateUser(user);
                    navigate('/app/', { replace: true });
                  }).catch((error) => {
                    console.log(error.message);
                    setErrorTextValue(error.message);
                  });
                  setSubmitting(false);
                }, 400);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (

                <form onSubmit={handleSubmit}>
                  <HeaderTitle>Login</HeaderTitle>
                  <ErrorText>{errorTextValue}</ErrorText>
                  <InputField
                    label="Email"
                    placeholder="your@email.eu"
                    onChange={handleChange('email')}
                    value={values.email}
                    onBlur={handleBlur('email')}
                  />
                  <ErrorText>{errors.email && touched.email && errors.email}</ErrorText>
                  <InputField
                    label="Password"
                    placeholder="your password"
                    isPassword
                    value={values.password}
                    onChange={handleChange('password')}
                    onBlur={handleBlur('password')}
                  />
                  <ErrorText>{errors.password && touched.password && errors.password}</ErrorText>
                  <LinkWrapper>
                    <ForgotPassword to="/forgot-password/">Forgot Password?</ForgotPassword>
                  </LinkWrapper>
                  <Actions>
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Login
                    </Button>
                  </Actions>
                  <SignUpContent>
                    or
                    <SignUplink to="/onboarding/"> SignUp</SignUplink>
                  </SignUpContent>
                </form>
              )}
            </Formik>
          </div>
        </LoginColumn>
      </LoginScreenLayoutContent>
    </LoginScreenLayout>
  );
}

const LoginScreenLayout = styled.div`
  display: flex;
  width: 100vw;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  background-image: url('https://res.cloudinary.com/dsvz8cktr/image/upload/c_scale,q_auto:low,w_1026/v1640345226/UI_things/TennisAppWebBackground_xrpgwn.jpg');
  backgroung-position: center;
  background-size: cover;
`;
const LoginScreenLayoutContent = styled.div`
  display: flex;
  width: 100vw;
  justify-content: center;
  font-family: ${theme.fonts.family1};
  align-items: flex-start;
`;
const LogoColumn = styled.div`
  text-align: right;
  margin-right: 56px;
`;
const LoginColumn = styled.div`
  width: 460px;
  margin-left: 56px;
  padding: 104px 68px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 13px;
`;
const HeaderTitle = styled.h3`
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 32px;
`;

const Label = styled.label`
  display: block;
  margin: 24px 0 8px;
  font-size: 13px;
`;

const STextInput = styled.input`
  width: 100%;
  padding: 13px 20px 11px 20px;
  line-height: 16px;
  font-size: 13px;
  border: 1px solid #fff;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.05);
  color: rgba(255, 255, 255, 0.5);
  &::placeholder,
  &:focus,
  &:active {
    color: rgba(255, 255, 255, 1);
  }
`;

const Actions = styled.div`
  margin-top: 24px;
`;
const LinkWrapper = styled.div`
  margin-top: 14px;
`;
const ForgotPassword = styled(NavLink)`
  font-size: 13px;
  font-family: ${theme.fonts.gotham13Regular};
  text-decoration: none;
  color: ${theme.colors.white};
`;
const SignUpContent = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 20px;
  font-size: 13px;
  font-family: ${theme.fonts.gotham13Regular};
  color: ${theme.colors.white};
`;
const SignUplink = styled(NavLink)`
  text-decoration: none;
  font-weight: 700;
  color: ${theme.colors.white};

`;
const ErrorText = styled.p`
  display: inline;
  font-size: 13px;
  font-family: ${theme.fonts.gotham13Regular};
  text-decoration: none;
  color: red;
`;
const Button = styled.button`
  display: block;
  width: 100%;
  border-radius: 8px;
  border-width: 0px;
  cursor: pointer;
  font-family: ${theme.fonts.family1};
  font-size: 17px;
  font-weight: 700;
  line-height: 48px;
  min-height: 48px;
  text-align: center;
  background-color: ${theme.colors.royalBlue};
  color: ${theme.colors.white};
  ${(props) => props.disabled && `
      opacity: 0.4;
    `}
`;
