import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from '../../styles/theme.styles';

// import mapPinIcon from '../../../assets/icons/mapPinIcon.png'
// import foot from '../../../assets/icons/foot.png'
// import footActive from '../../../assets/icons/footActive.png'

function InfoBitRow({
  title, icon, type, value, budget, currency,
}) {
  if (value === null || value === '') return null;
  return (
    <StyledInfoBitRow>
      <InfoTitle>{title}</InfoTitle>
      <ValueBox type={type} valueText={value}>
        {icon
          && (
          <ValueIcon>
            <svg width={12} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6 16c.344-.003.682-.09.986-.253.303-.164.563-.4.756-.687 1.936-2.914 4.259-6.847 4.259-8.997 0-1.608-.633-3.15-1.758-4.288A5.968 5.968 0 0 0 6 0a5.969 5.969 0 0 0-4.243 1.776A6.096 6.096 0 0 0 0 6.064c0 2.151 2.323 6.082 4.258 9.017.198.281.459.511.762.671.303.16.639.245.98.247ZM6 1.564c1.18 0 2.313.474 3.148 1.318a4.524 4.524 0 0 1 1.304 3.18c0 1.33-1.49 4.362-4.007 8.138a.551.551 0 0 1-.445.234.522.522 0 0 1-.445-.235C3.04 10.425 1.55 7.393 1.55 6.065c0-1.194.469-2.338 1.304-3.181A4.429 4.429 0 0 1 6 1.565Z"
                fill="#fff"
              />
              <path
                d="M8.38 5.868c0-.476-.14-.941-.4-1.337a2.386 2.386 0 0 0-1.07-.886 2.357 2.357 0 0 0-2.594.522A2.43 2.43 0 0 0 3.8 6.789c.18.439.486.815.877 1.079a2.363 2.363 0 0 0 3.006-.3 2.408 2.408 0 0 0 .697-1.7Zm-3.213 0a.847.847 0 0 1 .514-.777.824.824 0 0 1 .906.183A.844.844 0 0 1 6 6.709a.834.834 0 0 1-.587-.248.853.853 0 0 1-.246-.593Z"
                fill="#fff"
              />
            </svg>
          </ValueIcon>
          )}
        {type !== 'handedness' && type !== 'budget' && <ValueText valueText={value}>{value}</ValueText>}
        {type === 'handedness'
            && (
            <StyledHandedness handedness={value}>
              <HandIcon
                active={value === 'left' || value === 'both'}
              >
                <svg width={35} height={26} viewBox="0 0 49 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.192 0.000370369C19.5859 0.00515863 20.0046 0.0476285 20.4415 0.112999C23.2726 6.46962 25.6305 12.813 27.2544 19.1385C28.2217 18.6896 29.194 18.2464 30.165 17.8015C30.5762 12.2703 29.9697 7.06638 29.6102 1.79649C30.3072 1.26978 31.436 0.878391 32.5868 0.861737C33.7915 0.844457 34.9085 1.20836 35.5573 1.85197C36.1236 9.16135 36.1046 17.1904 35.4394 24.4895C33.157 27.5845 30.6639 30.725 29.9948 35.2777L31.9195 35.5604C32.3608 32.5587 33.7497 30.2163 35.3793 27.9085L36.1685 26.8104C36.69 26.0961 37.2234 25.3749 37.7492 24.6301C39.9764 21.6625 42.339 19.2704 44.219 18.4658C45.2402 18.029 46.017 18.0093 46.7831 18.4198C47.4582 18.7814 48.1904 19.6158 48.8694 21.0624C43.3098 26.4412 41.5229 31.8926 40.2591 35.4963C39.6564 37.2145 39.1057 38.7776 38.0446 40.3912C36.4193 42.863 32.8976 44.8578 29.2939 45.5448C25.6903 46.2316 22.1438 45.6189 20.2514 43.4804C18.7567 41.7912 15.9595 39.122 14.4019 37.4652C11.8492 34.7499 8.20999 31.7438 5.17965 29.4242C3.66448 28.2644 2.30462 27.2801 1.30772 26.5952C0.809118 26.2527 0.404198 25.9865 0.0989988 25.8015C0.0784925 25.789 0.0636065 25.7825 0.0441411 25.7709C-0.0922201 24.7432 0.0907731 23.62 0.5442 22.7132C0.997836 21.8057 1.66705 21.1874 2.3932 20.942C6.11003 23.1574 9.74422 25.5677 13.206 28.7946C13.804 28.0374 14.4482 27.331 15.1317 26.6686C11.9312 20.5464 7.84609 15.4526 3.23604 11.012C3.39842 10.0179 4.04379 8.94952 4.91338 8.1682C5.78776 7.3823 6.8418 6.95032 7.60438 6.95833C12.3526 11.7864 16.5422 17.0456 19.8353 23.0368C20.5554 22.5829 21.295 22.1506 22.0493 21.7346C20.0827 14.9982 17.5744 8.57458 14.3985 2.54075C15.7264 0.953858 17.0505 0.11175 18.8065 0.008802C18.9319 0.00151552 19.0605 -0.00119108 19.1917 0.000474405L19.192 0.000370369Z" fill="white" />
                </svg>

              </HandIcon>
              <ValueText isBoxed>{value}</ValueText>
              <HandIcon
                active={value === 'right' || value === 'both'}
              >
                <svg width={35} height={26} viewBox="0 0 50 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M29.9342 0.000370369C29.5403 0.00515863 29.1215 0.0476285 28.6847 0.112999C25.8536 6.46962 23.4956 12.813 21.8718 19.1385C20.9045 18.6896 19.9321 18.2464 18.9612 17.8015C18.55 12.2703 19.1564 7.06638 19.516 1.79649C18.819 1.26978 17.6902 0.878391 16.5394 0.861737C15.3347 0.844457 14.2177 1.20836 13.5689 1.85197C13.0026 9.16135 13.0215 17.1904 13.6868 24.4895C15.9691 27.5845 18.4623 30.725 19.1314 35.2777L17.2067 35.5604C16.7653 32.5587 15.3764 30.2163 13.7469 27.9085L12.9576 26.8104C12.4361 26.0961 11.9028 25.3749 11.377 24.6301C9.14972 21.6625 6.78713 19.2704 4.90711 18.4658C3.88596 18.029 3.10912 18.0093 2.3431 18.4198C1.66796 18.7814 0.935771 19.6158 0.256775 21.0624C5.81636 26.4412 7.60321 31.8926 8.8671 35.4963C9.4698 37.2145 10.0205 38.7776 11.0816 40.3912C12.7069 42.863 16.2285 44.8578 19.8322 45.5448C23.4359 46.2316 26.9823 45.6189 28.8747 43.4804C30.3695 41.7912 33.1667 39.122 34.7242 37.4652C37.277 34.7499 40.9162 31.7438 43.9465 29.4242C45.4617 28.2644 46.8215 27.2801 47.8184 26.5952C48.317 26.2527 48.722 25.9865 49.0272 25.8015C49.0477 25.789 49.0626 25.7825 49.082 25.7709C49.2184 24.7432 49.0354 23.62 48.582 22.7132C48.1283 21.8057 47.4591 21.1874 46.733 20.942C43.0161 23.1574 39.3819 25.5677 35.9201 28.7946C35.3221 28.0374 34.678 27.331 33.9944 26.6686C37.195 20.5464 41.2801 15.4526 45.8901 11.012C45.7277 10.0179 45.0824 8.94952 44.2128 8.1682C43.3384 7.3823 42.2844 6.95032 41.5218 6.95833C36.7736 11.7864 32.584 17.0456 29.2909 23.0368C28.5708 22.5829 27.8312 22.1506 27.0769 21.7346C29.0435 14.9982 31.5518 8.57458 34.7277 2.54075C33.3997 0.953858 32.0757 0.11175 30.3196 0.008802C30.1942 0.00151552 30.0657 -0.00119108 29.9345 0.000474405L29.9342 0.000370369Z" fill="white" />
                </svg>
              </HandIcon>
            </StyledHandedness>
            )}
        {type === 'budget'
          && (
          <>
            <ValueText isSmall valueText={value}>{value}</ValueText>
            <Budget valueText={value}>
              <Currency>
                {' '}
                {currency.toLocaleString('en-US')}
&nbsp;
              </Currency>
              {budget != null ? budget : 'N/A'}
            </Budget>
          </>
          )}
      </ValueBox>
    </StyledInfoBitRow>
  );
}

export default InfoBitRow;

export const StyledInfoBitRow = styled.div`
  background-color: ${theme.colors.transparentWhite2};
  border-radius: 6px;
  padding: 15px 15px;
  display: flex;
  flex-direction:row;
  align-items: center;
  justify-content: space-between;
`;
export const InfoTitle = styled.div`
  /* height: 40px; */
  /* width: 79px; */
  display:flex;
  justify-content: center;
  flex-direction:column;
  font-family: ${theme.fonts.infoBitTitle.family};
  font-size: 13px;
  font-weight: 600;
  line-height: ${theme.fonts.infoBitTitle.lineHeight};
  color: ${theme.colors.white};
  display: flex;
  align-items: left;
  flex-grow: 0;
  width:40%;
  padding-right: 15px;

`;
export const ValueBox = styled.div`
  background-color: ${theme.colors.transparentWhite2};
  padding: 12px ;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction:row;
  flex:1;
  ${(props) => props.valueText === 'Standby'
    && `background-color: ${props.theme.colors.transparentYellow};`
};
  ${(props) => props.type === 'budget' && 'flex-direction: column'}
`;
export const ValueIcon = styled.div`
  margin-right: 10px;
`;
export const ValueText = styled.div`
 text-align: center;
  font-family: ${theme.fonts.valueBoxText.family};
  font-size: ${theme.fonts.valueBoxText.size};
  font-weight: ${theme.fonts.valueBoxText.weight};
  line-height: ${theme.fonts.valueBoxText.lineHeight};
  color: ${theme.colors.white};
  ${(props) => props.valueText === 'Standby'
    && `color: ${props.theme.colors.gold};`
};
 ${(props) => props.isBoxed === true
    && ` 
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction:row;`
}

${(props) => props.isSmall === true
    && ` 
    font-size:10px
    `
}
`;

export const Budget = styled.div`
 text-align: center;
  font-family: ${theme.fonts.valueBoxText.family};
  font-size: 24px;
  font-weight: bold;
  line-height: ${theme.fonts.valueBoxText.lineHeight};
  color: ${theme.colors.white};
  ${(props) => props.valueText === 'Standby'
    && `color: ${props.theme.colors.gold};`
};
 ${(props) => props.isBoxed === true
    && ` 
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction:row;`
}
`;

export const Currency = styled.span`
 text-align: center;
  font-family: ${theme.fonts.valueBoxText.family};
  font-size: 13px;
  font-weight: bold;
  line-height: ${theme.fonts.valueBoxText.lineHeight};
  color: ${theme.colors.white};
  ${(props) => props.valueText === 'Standby'
    && `color: ${props.theme.colors.gold};`
};
 ${(props) => props.isBoxed === true
    && ` 
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction:row;`
}
`;

export const StyledHandedness = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content:space-between;
flex:1
`;

export const HandIcon = styled.div`
    ${(props) => !props.active && 'opacity: 0.4;'}
  
`;
