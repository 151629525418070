import React, { useState } from 'react';
import styled from 'styled-components';

import { Formik, Field } from 'formik';
import { API } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import theme from '../../styles/theme.styles';
import Stepper from '../../components/_FormElements/Stepper';
import InputCheckbox from '../../components/_FormElements/InputCheckBox';
import Popup from '../../components/_FormElements/Popup';
import LegalText from './LegalText';
import { createCoach } from '../../custom_graphql_queries/coachs';
import { signUp } from '../../services/authService';

import {
  FormLayout,
  HeaderTitle,
  HeaderInfoText,
  Form,
  Button,
  ErrorText,
  OpenModal,
  Text,
} from './OnBoarding.style';

function AcceptTermsStep({
  setStep,
  step,
  data,
  setData,
}) {
  const [termshavebeenreading, setTermshavebeenreading] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [errorTextValue, setErrorTextValue] = useState(false);

  const createProfile = async (data, profile_id) => {
    const {
      firstname, email, division, gender, password, phone, role, whatsapp,
    } = data;
    const profile_data = {
      id: profile_id,
      name: firstname,
      gender,
      contact_email: email,
      contact_phone: phone,
      allow_whatsapp_contact: whatsapp,
      is_active: false,
      role,
      teamID: '0e231f9a-73f6-4476-ad38-adb3f93c2513',
      sport: 'Tennis',
      division:division,
    };

    try {
      await API.graphql({
        query: createCoach,
        variables: { input: profile_data },
        authMode: 'API_KEY',
        apiKey: process.env.REACT_APP_AWS_APPSYNC_APIKEY,
      });
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }
  };

  const onChangeValueFormik = ({ form, fieldName, value }) => {
    form.setFieldValue(fieldName, value);
  };
  const handleClick = () => {
    setIsOpen(true);
    setTermshavebeenreading(true);
  };

  function InputCheckboxFieldAcceptTerms({
    field, colors, form, ...props
  }) {
    const { values, errors } = form;
    console.log(data);
    return (
      <InputCheckbox value={values.acceptTerms} fieldName="acceptTerms" formikForm={form} onChangeValueFormik={onChangeValueFormik} disabled={!termshavebeenreading} textAlign="center">
        <Text>I have read and I approve the Alliance Athletes App</Text>
        {' '}
        <OpenModal onClick={handleClick}>privacy terms</OpenModal>
      </InputCheckbox>
    );
  }

  return (
    <FormLayout>
      {isOpen && (
      <Popup
        isOpen={isOpen}
        bgcolor={theme.colors.brandDark}
        textAlign="left"
        content={<LegalText />}
        handleClose={() => setIsOpen(!isOpen)}
      />
      )}
      <HeaderTitle>Privacy Terms</HeaderTitle>
      <HeaderInfoText>You must read and agree with the privacy terms before moving forward</HeaderInfoText>
      {errorTextValue && <ErrorTextResponse>{errorTextValue}</ErrorTextResponse>}
      <Formik
        initialValues={{ terms: '' }}
        onSubmit={async (values, { setSubmitting }) => {
          const profile_id = uuidv4();
          const { email, password } = data;

          signUp(email, password, profile_id).then((response) => {
            setErrorTextValue(false);
            createProfile(data, profile_id).then(() => {
              setSubmitting(false);
              setStep(step + 1);
            }).catch((error) => {
              setErrorTextValue(error.message);
              throw new Error(error);
            });
          })
            .catch((error) => {
              setErrorTextValue(error.message);
              throw new Error(error);
            });
        }}
        validate={(values) => {
          const errors = {};
          if (!values.acceptTerms) errors.acceptTerms = 'Please, make sure you have read and approved the privacy terms';

          return errors;
        }}
      >
        {({
          handleChange, handleBlur, handleSubmit, errors, values, isSubmitting, touched,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Field name="acceptTerms" component={InputCheckboxFieldAcceptTerms} />
            {touched.acceptTerms && errors.acceptTerms && (<ErrorText>{errors.acceptTerms}</ErrorText>)}
            <Button type="submit" disabled={isSubmitting} step={step}>Next</Button>
            <Stepper active={step - 1} />
          </Form>
        )}
      </Formik>
    </FormLayout>
  );
}

export default AcceptTermsStep;

const ErrorTextResponse = styled(ErrorText)`
    margin-top: 30px;
`;
