import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import theme from '../../styles/theme.styles';
import InputField from '../../components/_FormElements/InputField';
import Stepper from '../../components/_FormElements/Stepper';
import logo from '../../assets/images/LoginLogo.png';
import { confirmSignUp } from '../../services/authService';

import {
  FormLayout,
  Form,
  Button,
  ErrorText,
} from './OnBoarding.style';

function ValidationStep({
  setStep,
  step,
  data,
}) {
  const [errorTextValue, setErrorTextValue] = useState(false);

  return (
    <FormLayout>
      <img src={logo} alt="logo" style={{ margin: '0 auto' }} />
      {errorTextValue && <ErrorText>{errorTextValue}</ErrorText>}
      <Formik
        initialValues={{ email: data.email || '', code: '' }}
        onSubmit={async (values, { setSubmitting }) => {
          const { email, code } = values;
          console.log(email, code);
          await confirmSignUp(email, code)
            .then((response) => {
              setErrorTextValue(response);
              setSubmitting(false);
              setStep(step + 1);
            })
            .catch((error) => {
              setErrorTextValue(error.message);
            });
        }}
        validate={() => {
          const errors = {};
          // setSubmitting(false);
          // if (!validator.isMobilePhone(values.phone, 'any', { strictMode: true }))
          //     errors.phone = 'Invalid phone number';

          return errors;
        }}
      >
        {({
          handleChange, handleBlur, handleSubmit, errors, values, touched,
        }) => (
          <Form onSubmit={handleSubmit}>
            <InputField
              label="Email"
              placeholder="your@email.eu"
              value={values.email}
              onBlur={handleBlur('email')}
              onChange={handleChange('email')}
            />
            {touched.email && errors.email && (<ErrorTextLeft>{errors.email}</ErrorTextLeft>)}
            <InputField
              label="Code"
              placeholder="123456"
              value={values.code}
              onBlur={handleBlur('code')}
              onChange={handleChange('code')}
            />
            {touched.code && errors.code && (<ErrorTextLeft>{errors.code}</ErrorTextLeft>)}
            <InfoContainer>
              <InfoText>
                * You should have received an email with a 6-digits code to confirm your e-mail account (check your spam box if you do not find the e-mail in your inbox). Please, enter the code in the field above to complete the e-mail verification process.
              </InfoText>
            </InfoContainer>
            <Button type="submit" step={step}>Next</Button>

            <Stepper active={step - 1} />
          </Form>
        )}
      </Formik>
    </FormLayout>
  );
}

const ErrorTextLeft = styled(ErrorText)`
    text-align: left;
`;
const InfoText = styled.div`
  font-family: ${theme.fonts.family1};;
  font-size: 12px;
  line-height: 17px;
  color: ${theme.colors.white};
  font-style: italic;
`;
export const InfoContainer = styled.div`
    margin-top: 20px;
  padding: 20px;
  border: 2px solid ${theme.colors.white50};
`;

export default ValidationStep;
