// Context
import React, { createContext, useState, useEffect } from 'react';

import { API, Auth, Hub } from 'aws-amplify';
import {
  shuffle,
  filter,
  each,
  find,
  uniqBy,
  sortBy,
  toLower,
  size,
  indexOf,
} from 'lodash';

// Grapql Queries
import Fuse from 'fuse.js';
import { listAgencys } from '../custom_graphql_queries/agencies';
import { listAgents } from '../custom_graphql_queries/agents';
import {
  listAthletes,
  createAthleteEnquiry,
} from '../custom_graphql_queries/athletes';
import {
  getCoach,
  createCoachAthleteFavorites,
  deleteCoachAthleteFavorites,
  createCoachMessage,
} from '../custom_graphql_queries/coachs';
import { createFeedback } from '../custom_graphql_queries/feedback';
import selectorsContentRaw from './selectorsContent';

export const DataContext = createContext();

function DataContextProvider({ children }) {
  const [user, setUser] = useState(null);
  const [userCoachData, setUserCoachData] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [agencies, setAgencies] = useState([]);
  const [numberOfAgencies, setNumberOfAgencies] = useState(-1);
  const [agents, setAgents] = useState([]);
  const [athletes, setAthletes] = useState([]);

  const [isDataProcessed, setIsDataProcessed] = useState(false);
  const [searchIdx, setSearchIdx] = useState(null);

  const [filteredAthletes, setFilteredAthletes] = useState([]);
  // This contains internal search filters (and search).
  const [filters, setFilters] = useState({
    searchString: '',
    filters: {},
  });
  const [selectorsContent, setSelectorContent] = useState({});
  const [lastSavedToApiSearchString, setLastSavedToApiSearchString] = useState('');

  const updateUser = (newUser) => {
    setUser(newUser);
  };

  const updateLoading = (newLoading) => {
    setLoading(newLoading);
  };

  // In these queries we get all the data, not only for listing

  // Coach
  // User Coach Data. Cognito user is linked to coach by custon field profileId
  const fetchUserCoachData = async () => {
    // onsole.log("fetchUserCoachData")
    try {
      const coach = await API.graphql({
        query: getCoach,
        variables: { id: user.attributes['custom:profile_id'] },
      });
      if (coach.data.getCoach) {
        setUserCoachData(coach.data.getCoach);
      }
    } catch (e) {
      // onsole.log("fetchCoachSimple ERRORES")
      // onsole.log(e);
    }
  };

  // Agencies
  const fetchAgencies = async () => {
    let agenciesRes = [];
    let response = {};
    const params = {
      query: listAgencys,
      variables: {
        limit: 500,
        filter: { or: [{ sport: { eq: 'Tennis' } }, { sport: { eq: 'Both' } }] },
      },

    };
    try {
      response = await API.graphql(params); // He make the first request to API
      agenciesRes = response.data.listAgencys.items;
      while (response.data.listAgencys.nextToken) {
        // Then repeat the same request with the nexttoken until it's nul'
        params.variables.nextToken = response.data.listAgencys.nextToken;
        response = await API.graphql(params);
        agenciesRes = [...agenciesRes, ...response.data.listAgencys.items];
      }

      setNumberOfAgencies(agenciesRes.length); // to check loading
      setAgencies(agenciesRes);
    } catch (e) {
      throw ('fetchagencies ERROR!!!!!', e);
    }
  };

  // Agents
  const fetchAgents = async () => {
    try {
      const tempAgents = await API.graphql({
        query: listAgents,
        variables: { limit: 50000 },
      });
      if (tempAgents.data.listAgents) {
        setAgents(tempAgents.data.listAgents.items);
      }
    } catch (e) {
      throw ('fetchAgents ERROR!!!!!', e);
    }
  };

  // Athletes
  const fetchAthletes = async () => {
    let athletes = [];
    let response = {};
    const params = {
      query: listAthletes,
      variables: {
        limit: 500,
        filter: {
          sport: {
            eq: 'Tennis',
          },
        },
      },
    };
    response = await API.graphql(params); // He make the first request to API
    athletes = response.data.listAthletes.items;
    while (response.data.listAthletes.nextToken) {
      // Then repeat the same request with the nexttoken until it's nul'
      params.variables.nextToken = response.data.listAthletes.nextToken;
      response = await API.graphql(params);
      athletes = [...athletes, ...response.data.listAthletes.items];
    }

    setAthletes(
      shuffle(
        athletes,
      ),
    ); // Filtro temporal para quitar los no visibles. Esto tiene que estar en el servidor
  };

  //    _____                               _____        _
  //   |  __ \                             |  __ \      | |
  //   | |__) | __ ___   ___ ___  ___ ___  | |  | | __ _| |_ __ _
  //   |  ___/ '__/ _ \ / __/ _ \/ __/ __| | |  | |/ _` | __/ _` |
  //   | |   | | | (_) | (_|  __/\__ \__ \ | |__| | (_| | || (_| |
  //   |_|   |_|  \___/ \___\___||___/___/ |_____/ \__,_|\__\__,_|
  //

  // Generate search index of athletes (part of Process Data)
  const generateSearchIndex = () => {
    const idx = [];
    athletes.forEach((item) => {
      const athleteForSearch = {
        id: item.id,
        text1: toLower(
          `${item.name
          } ${
            item.contact_email
          } ${
            item.gender
          } ${
            item.gender === 'male' ? 'man boy ' : ''
          }${item.gender === 'female' ? 'woman girl ' : ''
          }${item.contact_phone
          } ${
            item.nationality
          } ${
            item.country_of_competition
          } ${
            item.current_club}`,
        ),
        text2: toLower(item.elegibility_notes),
      };
      idx.push(athleteForSearch);
    });
    const fuseOptions = {
      isCaseSensitive: false,
      threshold: 0.2,
      ignoreLocation: true,
      keys: ['text1', 'text2'],
    };
    setSearchIdx(new Fuse(idx, fuseOptions));
  };

  const completeSelectorContent = () => {
    // Agencies
    selectorsContentRaw.agencyID.valueSets = agencies.map((agency) => [agency.id, agency.name]);

    // Countries
    const countries = [];
    each(athletes, (athlete) => {
      if (athlete.country_of_competition !== null) {
        countries.push({ name: athlete.country_of_competition });
      }
    });
    const countriesUniq = uniqBy(countries, 'name');
    const countriesSorted = sortBy(countriesUniq, 'name');
    selectorsContentRaw.country_of_competition.valueSets = countriesSorted.map(
      (country) => [country.name, country.name],
    );

    setSelectorContent(selectorsContentRaw);
  };

  const processAllData = () => {
    // Number of athletes in agencies
    const tempAgencies = [...agencies];
    each(tempAgencies, (agency) => {
      agency.athleteCount = filter(athletes, { agencyID: agency.id }).length;
    });
    // Add agent data into agency field
    each(tempAgencies, (agency, idxAgency) => {
      // const tempAgents = [];
      each(agency.agents.items, (agent, idxAgent) => {
        const agentInfo = find(agents, { id: agent.id });
        tempAgencies[idxAgency].agents.items[idxAgent] = agentInfo;
      });
    });
    setAgencies(tempAgencies);

    const tempAthletes = [...athletes];
    // Mark isFavorited in athlete if is favorited of logged user
    each(tempAthletes, (athlete) => {
      athlete.isFavorited = !!find(userCoachData.favorites.items, {
        athleteID: athlete.id,
      });
    });

    // Add extra agency info (athleteCount) and Agent info to athlete
    each(tempAthletes, (athlete) => {
      const athleteAgent = find(agents, { id: athlete.agentID });
      athlete.agent = athleteAgent;
      const athleteAgency = find(agencies, { id: athlete.agency.id });
      athlete.agency = athleteAgency;
    });
    // DELETE THIS
    // each(tempAthletes, (athlete) => {
    //   if(athlete.budget!==null){
    //     console.log(athlete.budget)
    //     console.log(athlete.id)
    //   }
    // })

    setAthletes(tempAthletes);
    setFilteredAthletes(tempAthletes); // When load, there is no filters

    // Search data for athlete search
    generateSearchIndex();

    // Complete data for form selector Pickers form API
    completeSelectorContent();
    updateLoading(false);
  };

  //    ______ _ _ _
  //   |  ____(_) | |
  //   | |__   _| | |_ ___ _ __ ___
  //   |  __| | | | __/ _ \ '__/ __|
  //   | |    | | | ||  __/ |  \__ \
  //   |_|    |_|_|\__\___|_|  |___/
  //

  // First we need to detect when the filters has changed.
  // Externally can change by the function updateFilters(...)
  const athleteSearchAndFilter = () => {
    let tempAthletes = [...athletes];
    if (searchIdx) {
      if (filters.searchString !== '') {
        const tempSearcherdAthletes = [...tempAthletes];
        const itemsResult = [];
        const indexesResult = searchIdx.search(filters.searchString);
        each(indexesResult, (index) => {
          const item = find(tempSearcherdAthletes, { id: index.item.id });
          if (item) {
            itemsResult.push(item);
          }
        });
        tempAthletes = [...itemsResult];
      }
    }
    if (filters.filters) {
      // console.log(filters.filters)
      const tempFilteredAthletes = filter(tempAthletes, (item) => {
        let includedFilters = size(filters.filters);
        each(filters.filters, (filter, index) => {
          switch (index) {
            case 'start_date':
            case 'gender':
            case 'country_of_competition':
            case 'agencyID':
            case 'type_of_admission':
            case 'current_status':
              if (filter.eq === item[index]) {
                includedFilters -= 1;
              }
              break;
            case 'dominant_hand':
              if (filter.eq === item.tennis_profile.DominantHand) {
                includedFilters -= 1;
              }
              break;
            case 'utr':
              if (
                item.tennis_profile
                && parseFloat(filter.ge) <= item.tennis_profile[index]
                && item.tennis_profile[index] > 0
              ) {
                includedFilters -= 1;
              }
              break;
            case 'national_ranking':
            case 'international_ranking':
              if (
                item.tennis_profile
                && parseInt(filter.ge, 10) >= item.tennis_profile[index]
                && item.tennis_profile[index] > 0
              ) {
                includedFilters -= 1;
              }
              break;

            case 'target_division':
              if (indexOf(item[index], filter.eq) > -1) {
                includedFilters -= 1;
              }
              break;
            case 'sat_score':
            case 'toefl_score':
            case 'estimated_gpa':
              if (parseFloat(filter.ge) <= item[index]) {
                includedFilters -= 1;
              }
              break;
            case 'psawithvideos':
              if (
                filter.eq === 'Highlight and Other Videos'
                && (Boolean(item.featured_video) && item.other_videos)
              ) {
                includedFilters -= 1;
              }
              if (
                filter.eq === 'Highlight Video'
                && (Boolean(item.featured_video) && !item.other_videos)
              ) {
                includedFilters -= 1;
              }
              if (
                filter.eq === 'Other Videos'
                && (item.other_videos) && Boolean(!item.featured_video)
              ) {
                includedFilters -= 1;
              }
              break;
            default:
              break;
          }
        });

        return includedFilters === 0;
      });

      tempAthletes = [...tempFilteredAthletes];
    }

    setFilteredAthletes(tempAthletes);
  };

  useEffect(() => {
    athleteSearchAndFilter();
  }, [filters]);

  //    ______                    _ _
  //   |  ____|                  (_) |
  //   | |__ __ ___   _____  _ __ _| |_ ___  ___
  //   |  __/ _` \ \ / / _ \| '__| | __/ _ \/ __|
  //   | | | (_| |\ V / (_) | |  | | ||  __/\__ \
  //   |_|  \__,_| \_/ \___/|_|  |_|\__\___||___/
  //

  // Agencies
  const favoriteToggle = async (athlete) => {
    const tempAthletes = [...athletes];
    const athleteIdx = tempAthletes.findIndex((obj) => obj.id === athlete.id);
    tempAthletes[athleteIdx].isFavoritedLoading = true;
    setAthletes(tempAthletes);
    let query;
    if (!athlete.isFavorited) {
      query = {
        query: createCoachAthleteFavorites,
        variables: {
          input: {
            athleteID: athlete.id,
            coachID: userCoachData.id,
          },
        },
      };
    } else {
      const favorite = find(userCoachData.favorites.items, {
        athleteID: athlete.id,
      });
      query = {
        query: deleteCoachAthleteFavorites,
        variables: {
          input: {
            id: favorite.id,
          },
        },
      };
    }
    try {
      const enquery = await API.graphql(query);
      if (enquery) {
        fetchUserCoachData();
        tempAthletes[athleteIdx].isFavorited = !athlete.isFavorited;
        tempAthletes[athleteIdx].isFavoritedLoading = false;
        setAthletes(tempAthletes);
      }
    } catch (e) {
      throw ('favoriteToggle ERROR!!!!!', e);
    }
  };

  //                    _             _      __
  //                   | |           | |    / _|
  //     ___ ___  _ __ | |_ __ _  ___| |_  | |_ ___  _ __ _ __ ___
  //    / __/ _ \| '_ \| __/ _` |/ __| __| |  _/ _ \| '__| '_ ` _ \
  //   | (_| (_) | | | | || (_| | (__| |_  | || (_) | |  | | | | | |
  //    \___\___/|_| |_|\__\__,_|\___|\__| |_| \___/|_|  |_| |_| |_|
  //
  //
  const coachToAgencyFormSend = async ({ message, agencyId }) => {
    try {
      const enquery = await API.graphql({
        query: createCoachMessage,
        variables: {
          input: {
            agencyID: agencyId,
            message,
            coachID: userCoachData.id,
          },
        },
      });
      if (enquery) {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  };
  const feedBackFormSend = async ({ message }) => {
    try {
      const enquery = await API.graphql({
        query: createFeedback,
        variables: {
          input: {
            feedback_text: message,
            coachID: userCoachData.id,
          },
        },
      });
      if (enquery) {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  };
  const requestInfoOfAthleteFormSend = async ({ message, athleteId }) => {
    try {
      const enquery = await API.graphql({
        query: createAthleteEnquiry,
        variables: {
          input: {
            athleteID: athleteId,
            enquiry_text: message,
            coachID: userCoachData.id,
          },
        },
      });
      if (enquery) {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  };

  // AUTH
  const authCheckUser = async () => {
    // This function check us user is logged in the session.
    // The time of session can by modified in AWS console.log()
    try {
      const authUser = await Auth.currentAuthenticatedUser();
      updateUser(authUser);
    } catch (error) {
      updateUser('notLoggedIn');
    }
  };

  const authSignIn = async (username, password) => {
    try {
      const authUser = await Auth.signIn(username, password);
      if (authUser.attributes && authUser.attributes['custom:disabledByUser'] === 'True') {
        await Auth.signOut();
        throw new Error('User does not exist.');
      }
      if (authUser.attributes && authUser.attributes['custom:isActive'] === 'False') {
        await Auth.signOut();
        throw new Error('User is not actived.');
      }
      return authUser;
      // onsole.log(authUser)
    } catch (error) {
      // console.log('error signing in', error.message);
      throw new Error(error.message);
    }
  };
  const authSignOut = async () => {
    try {
      await Auth.signOut();
    } catch (error) {
      // onsole.log('error signing out', error);
    }
  };

  //    _                     _       _       _
  //   | |                   | |     | |     | |
  //   | |     ___   __ _  __| |   __| | __ _| |_ __ _
  //   | |    / _ \ / _` |/ _` |  / _` |/ _` | __/ _` |
  //   | |___| (_) | (_| | (_| | | (_| | (_| | || (_| |
  //   |______\___/ \__,_|\__,_|  \__,_|\__,_|\__\__,_|
  //

  // When user is logged in, load all data, an then process the data
  // to link connect information between
  const loadAllData = async () => {
    updateLoading(true);
    fetchUserCoachData();
    fetchAgencies();
    fetchAgents();
    fetchAthletes();
  };

  //     _____ _               _
  //    / ____| |             | |
  //   | |    | |__   ___  ___| | __  _   _ ___  ___ _ __
  //   | |    | '_ \ / _ \/ __| |/ / | | | / __|/ _ \ '__|
  //   | |____| | | |  __/ (__|   <  | |_| \__ \  __/ |
  //    \_____|_| |_|\___|\___|_|\_\  \__,_|___/\___|_|
  //

  const setAuthListener = async () => {
    Hub.listen('auth', (data) => {
      switch (data.payload.event) {
        // case 'signIn':
        //  If user change a loged in, usualy he is in
        // login. We update user in dataContext and goes to /app
        //   updateUser(data.payload.data)
        //   navigate('/app/')
        //   break
        case 'signOut':
          // If user change a loged out, we update user in dataContext.
          // After this change, Router automaticaly moves user to /login
          updateUser('notLoggedIn');
          break;

        default:
          break;
      }
    });
  };

  // This effect executes once, when user enter to the app.
  useEffect(() => {
    // First we check if user is logged in.
    authCheckUser();
    // then we listen auth changes thanks to AWS Hub tool.
    setAuthListener();
  }, []);

  // is user is loaded, load data
  useEffect(() => {
    if (user !== null && user !== 'notLoggedIn') {
      loadAllData();
    }
  }, [user]);

  const checkAllLoaded = () => {
    const agenciesLoaded = agencies.length === numberOfAgencies && numberOfAgencies > 0;
    const agentsLoaded = agents.length > 0;
    const athletesLoaded = athletes.length > 0;
    const userCoachDataLoaded = userCoachData !== null;

    if (
      agenciesLoaded
      && agentsLoaded
      && athletesLoaded
      && userCoachDataLoaded
    ) {
      // onsole.log("processing")
      if (!isDataProcessed) {
        setIsDataProcessed(true);
        processAllData();
      } else { updateLoading(false); }
    }
  };

  // Check if all data is loaded before process it
  useEffect(() => {
    checkAllLoaded();
  }, [agencies, agents, athletes, userCoachData]);

  return (
    <DataContext.Provider
      value={{
        user,
        updateUser,
        userCoachData,
        isLoading,
        updateLoading,
        isDataProcessed,
        athletes,
        filteredAthletes,
        filters,
        updateFilters: setFilters,
        selectorsContent,
        authCheckUser,
        authSignIn,
        authSignOut,
        favoriteToggle,
        agencies,
        coachToAgencyFormSend,
        feedBackFormSend,
        requestInfoOfAthleteFormSend,
        lastSavedToApiSearchString,
        updateLastSavedToApiSearchString: setLastSavedToApiSearchString,
      }}
    >
      {children}
    </DataContext.Provider>
  );
}
export default DataContextProvider;
