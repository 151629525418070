import React from 'react';
import styled from 'styled-components';
import theme from '../../styles/theme.styles';

function Stepper({ active }) {
  return (
    <StepperBox>
      { [1, 2, 3, 4, 5].map((item, index) => (
        <StepperDot>
          <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="4" cy="4" r="4" transform="rotate(-180 4 4)" fill={index === active ? theme.colors.royalBlue : theme.colors.darkInactiveBlue} />
          </svg>
        </StepperDot>
      ))}
    </StepperBox>
  );
}

export default Stepper;

export const StepperBox = styled.div`
  flex: 1;
  justify-content: center;
  margin-top: 3%;
`;
export const StepperDot = styled.div`
    display: inline;
    margin: 5px;
`;
