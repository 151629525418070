import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from '../../styles/theme.styles';

function LayoutWrapper({ children, isFixed }) {
  return (
    <SLayoutWrapper styles={styled} isFixed={isFixed}>
      <div>{children}</div>
    </SLayoutWrapper>
  );
}
export default LayoutWrapper;

LayoutWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  isFixed: PropTypes.bool,
};

LayoutWrapper.defaultProps = {
  isFixed: false,
};

const SLayoutWrapper = styled.div`
    ${(props) => props.isFixed
        && `position: fixed;
        top: 0;
        right: 0;
        left: 0;`
}
    padding: 0 ${theme.sizes.padding.tabletPortrait}px;
    & > div{
        margin: 0 auto;
        max-width: ${theme.sizes.maxWidth}px;
    }
`;
