function LoadingSvg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{
        margin: 'auto',
        background: '0 0',
        display: 'block',
        shapeRendering: 'auto',
      }}
      width={54}
      height={54}
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      {...props}
    >
      <circle cx={84} cy={50} r={10} fill="#93dbe9">
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="0.5s"
          calcMode="spline"
          keyTimes="0;1"
          values="10;0"
          keySplines="0 0.5 0.5 1"
          begin="0s"
        />
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          calcMode="discrete"
          keyTimes="0;0.25;0.5;0.75;1"
          values="#93dbe9;#2049da;#485fdc;#689cc5;#93dbe9"
          begin="0s"
        />
      </circle>
      <circle cx={16} cy={50} r={10} fill="#93dbe9">
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="2s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="0;0;10;10;10"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="0s"
        />
        <animate
          attributeName="cx"
          repeatCount="indefinite"
          dur="2s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="16;16;16;50;84"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="0s"
        />
      </circle>
      <circle cx={50} cy={50} r={10} fill="#689cc5">
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="2s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="0;0;10;10;10"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-0.5s"
        />
        <animate
          attributeName="cx"
          repeatCount="indefinite"
          dur="2s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="16;16;16;50;84"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-0.5s"
        />
      </circle>
      <circle cx={84} cy={50} r={10} fill="#485fdc">
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="2s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="0;0;10;10;10"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-1s"
        />
        <animate
          attributeName="cx"
          repeatCount="indefinite"
          dur="2s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="16;16;16;50;84"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-1s"
        />
      </circle>
      <circle cx={16} cy={50} r={10} fill="#2049da">
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="2s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="0;0;10;10;10"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-1.5s"
        />
        <animate
          attributeName="cx"
          repeatCount="indefinite"
          dur="2s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="16;16;16;50;84"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-1.5s"
        />
      </circle>
    </svg>
  );
}

export default LoadingSvg;
