import React, { useState, useEffect } from 'react';
import Sstyled from 'styled-components';
import PropTypes from 'prop-types';
import Slider, { SliderValueLabelProps } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import theme from '../../styles/theme.styles';

function FormSlider({
  type, onChange, isFullWidth, min, max, step, unit, resetFilters,
}) {
  const [sliderValue, setSliderValue] = useState(min);
  const onSliderChange = (value) => {
    setSliderValue(value);
    onChange(value);
  };
  useEffect(() => {
    if (resetFilters === true) {
      setSliderValue(min);
    }
  }, [resetFilters]);

  return (
    <Container isFullWidth={isFullWidth}>
      <SliderContainer>
        <StyledSlider
          size="small"
          onChange={(e, val) => onSliderChange(val)}
          defaultValue={min}
          value={sliderValue}
          aria-label="Small"
          // valueLabelDisplay="auto"
          step={step}
          min={min}
          max={max}
        />

      </SliderContainer>
      <Value>
        {sliderValue}
        {' '}
        {unit}
      </Value>
    </Container>
  );
}

FormSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  isFullWidth: PropTypes.bool,
  step: PropTypes.number.isRequired,
  unit: PropTypes.string,
};

FormSlider.defaultProps = {
  min: 0,
  max: 100,
  onChange: () => {},
  isFullWidth: true,
  step: 1,
  unit: '',
};

const StyledSlider = styled(Slider)({
  width: '100%',
  color: theme.colors.transparentWhite3,
  height: 3,

  '& .MuiSlider-root': {
    padding: 0,
  },
  '& .MuiSlider-track': {
    border: 'none',
    backgroundColor: theme.colors.royalBlue,
  },
  '& .MuiSlider-thumb': {
    height: 16,
    width: 16,
    backgroundColor: '#DFE1EA',
    // border: "1px solid currentColor",
    '&:focus, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
      border: `2px solid ${theme.colors.royalBlue}`,
    },
    '&:hover': {
      boxShadow: 'inherit',
      backgroundColor: '#DFE1EA',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: theme.colors.royalBlue,
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

export default FormSlider;

const Container = Sstyled.div`
  position: relative;
  display: ${(props) => (props.isFullWidth ? 'block' : 'inline-block')};
  margin: 0px;

`;
const SliderContainer = Sstyled.div`
  position: relative;
  padding: 2px 6px 0px 6px;
`;
// const StyledSlider = styled(ReactSlider)`
//     width: 100%;
//     height: 3px;
// `;

const StyledThumb = Sstyled.div`
    height: 16px;
    line-height: 16px;
    width: 16px;
    background-color: #DFE1EA;
    border-radius: 50%;
    cursor: grab;

    border: 3px solid #FFFFFF;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.5);
    border-radius: 80px;
    transform: translate(0px, -7px);
`;
const StyledTrack = Sstyled.div`
    top: 0;
    bottom: 0;
    background: ${(props) => (props.index === 1 ? theme.colors.transparentWhite3 : theme.colors.royalBlue)};
    border-radius: 999px;
`;
const Value = Sstyled.div`
  padding-top: 0px;
  font-size: 15px;
  text-align: right;
  & span{
    font-size: 13px;
  }
`;
