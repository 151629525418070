import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { NavLink, useNavigate } from 'react-router-dom';
import { Navigation } from 'swiper';
import { getAge } from '../../helpers';
import theme from '../../styles/theme.styles';

function ListItem({ athlete, path, favoriteToggle }) {
  const {
    name,
    isFavorited,
    isFavoritedLoading,
    featured_video,
    // agency,
    card_pic,
    profile_pic,
    country_of_competition,
    start_date,
    birthdate,
    gender,
    current_status,
    tennis_profile,
  } = athlete;

  let availablePic = card_pic || profile_pic;
  if (availablePic) {
    let committed_layer = '';
    if (current_status === 'COMMITTED') {
      committed_layer = 'e_grayscale/l_commited-watermark_cbwmm6/c_scale,w_0.35/fl_layer_apply/';
    }
    availablePic = availablePic.replaceAll(
      'image/upload/',
      `image/upload/c_thumb,e_auto_brightness,g_face,z_0.01,h_254,w_346/${committed_layer}`,
    );
  }
  const profileImage = availablePic || (gender === 'female'
    ? '/images/avatar_girl_horizontal.svg'
    : '/images/avatar_boy_horizontal.svg');
  const semester = start_date?.split('_').join(' ');

  const navigate = useNavigate();

  return (
    <ListItemContainer>
      <SListItem to={path}>
        <BlockAvatar>
          <Avatar image={profileImage} />
          {featured_video && (
            <VideoIcon>
              <svg
                width={16}
                height={16}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16 4V2A2 2 0 0013.834.007l-12 1A2 2 0 000 3v2c.003.352.1.697.279 1-.18.302-.277.648-.279 1v7a2 2 0 002 2h12a2 2 0 002-2V7a1.988 1.988 0 00-.689-1.5A1.992 1.992 0 0016 4zm-2-2v2l-1.846.154-1.846-1.846L14 2zM6.615 4.615L4.769 2.769l3.693-.308 1.846 1.847-3.693.307zM2 3l.923-.077 1.846 1.846L2 5V3zm12 11H2V7h12v7zM6 8v5l4.75-2.5L6 8z"
                  fill="#fff"
                />
              </svg>
            </VideoIcon>
          )}
        </BlockAvatar>
        <Texts>
          <HeaderLine>
            <strong>{name}</strong>
            {' '}
            {new Date(birthdate).getFullYear()}
            {' '}
          </HeaderLine>
          <TextLine status={current_status}>
            <svg width="14" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.427395 0.640897C0.25671 0.687323 0.143375 0.784273 0.0559847 0.96042L0 1.07102V7.45191V13.8328L0.068274 13.8847C0.165223 13.9584 0.274461 13.9885 0.477918 13.998C0.741456 14.0089 0.925795 13.9748 1.04732 13.8902L1.10604 13.8506V7.44508V1.04098L1.06371 0.96042C0.977684 0.799293 0.847963 0.690054 0.686836 0.646359C0.578963 0.616319 0.524344 0.614953 0.427395 0.640897Z" fill="white" />
              <path d="M2.01 5.09509L2.0141 8.3818L2.09602 8.40638C2.32406 8.47739 2.46334 8.5006 2.69001 8.50879C3.11604 8.52245 3.48608 8.43369 3.98175 8.19883C4.46786 7.96806 4.92257 7.66493 5.847 6.95488C6.6895 6.30628 7.00902 6.07141 7.34629 5.85294C7.94984 5.46241 8.43322 5.25759 8.92206 5.18658C9.15282 5.15244 9.49693 5.16473 9.72769 5.21526L9.90384 5.25486L9.93524 5.21389C10.0718 5.04457 9.95846 4.76874 9.61845 4.44513C9.03812 3.88938 8.41137 3.55756 7.6467 3.4019C7.4132 3.35274 7.23296 3.34045 6.52018 3.32407C4.65084 3.28037 4.56891 3.27491 4.11693 3.16021C3.40962 2.98133 2.85933 2.64952 2.25852 2.04461C2.1288 1.91489 2.02092 1.80838 2.01546 1.80838C2.01136 1.80838 2.00863 3.2872 2.01 5.09509Z" fill="white" />
            </svg>
            <StatusLine>
              {(current_status === 'ON_HOLD' ? 'ON HOLD' : current_status)}
            </StatusLine>
          </TextLine>
          <TextLine>
            <svg
              width={14}
              height={14}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.622 9.144l-.398-3.98a.872.872 0 00-.87-.789H5.646a.872.872 0 00-.87.787l-.398 3.982a.44.44 0 00.434.481h.479l.359 3.588c.044.448.42.787.871.787h.958c.452 0 .826-.34.871-.787l.359-3.588h.478a.434.434 0 00.435-.481zM7 3.5A1.75 1.75 0 107 0a1.75 1.75 0 000 3.5z"
                fill="#fff"
              />
            </svg>
            {(tennis_profile?.utr) ? `${tennis_profile?.utr} UTR` : ''}
          </TextLine>
          <TextLine>
            <svg
              width={14}
              height={14}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 12.833A5.833 5.833 0 107 1.166a5.833 5.833 0 000 11.667zM1.167 6.999h11.666"
                stroke="#fff"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7 1.166a8.925 8.925 0 012.333 5.833A8.925 8.925 0 017 12.833a8.925 8.925 0 01-2.333-5.834A8.925 8.925 0 017 1.166v0z"
                stroke="#fff"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            {country_of_competition}
          </TextLine>
          <TextLine>
            <svg
              width={14}
              height={14}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.468 1.75h-1.49a.753.753 0 00-.218-.53.742.742 0 00-1.27.53H5.51a.753.753 0 00-.217-.53.742.742 0 00-1.272.53H2.532c-.395 0-.774.158-1.053.44-.28.28-.436.662-.436 1.06v8.25c0 .398.157.78.436 1.06.28.282.658.44 1.053.44h8.936c.395 0 .774-.158 1.053-.44.28-.28.436-.662.436-1.06V3.25c0-.398-.157-.78-.436-1.06a1.484 1.484 0 00-1.053-.44zm-8.936 1.5h1.49c0 .199.078.39.218.53a.742.742 0 001.27-.53h2.98a.742.742 0 101.49 0h1.488v1.5H2.532v-1.5zm0 8.25V6.25h8.936v5.25H2.532z"
                fill="#fff"
              />
            </svg>
            {semester || 'N/A'}
          </TextLine>
        </Texts>
      </SListItem>
      <FavoriteButton
        onClick={() => {
          favoriteToggle(athlete);
        }}
        isFavoritedLoading={isFavoritedLoading}
        isFavorited={isFavorited}
      >
        <svg
          width={20}
          height={18}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.238 3.309a4.471 4.471 0 00-6.327 0l-.862.862-.863-.862A4.474 4.474 0 002.86 9.636l7.19 7.19 7.189-7.19a4.472 4.472 0 000-6.327z"
            fill="#fff"
            stroke="#fff"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </FavoriteButton>
      <AgencyLogo>
        <img src={athlete.agency.logo} alt="Agency Logo" onClick={() => navigate(`/agencies/agency_${athlete.agencyID}`)} />
      </AgencyLogo>
    </ListItemContainer>
  );
}

ListItem.propTypes = {
  athlete: PropTypes.object,
};

ListItem.defaultProps = {
  athlete: {},
};

export default ListItem;

const ListItemContainer = styled.div`
  position: relative;
  margin-bottom: 16px;
`;
const FavoriteButton = styled.div`
  position: absolute;
  top: 21px;
  right: 21px;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background-color: ${(props) => (props.isFavorited ? theme.colors.royalBlue : 'rgba(255, 255, 255, 0.1)')};
  cursor: pointer;
  & > svg {
    margin: 9px 0 0 8px;
    opacity: ${(props) => (props.isFavorited ? 1 : 0.2)};
  }
  @keyframes blink {
    0% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }
  ${(props) => props.isFavoritedLoading
    && `
    & > svg{
        animation: blink normal 0.5s infinite ease-in-out;
    }
    `}
`;
const SListItem = styled(NavLink)`
  display: flex;
  padding: 12px;
  border-radius: 13px;
  color: ${theme.colors.white};
  text-decoration: none;
  background-color: ${theme.colors.transparentWhite2};
`;
const BlockAvatar = styled.div`
  position: relative;
  width: 173px;
  height: 147px;
`;
const VideoIcon = styled.span`
  position: absolute;
  width: 16px;
  height: 16px;
  top: 13px;
  right: 13px;
  & svg {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
`;
const Avatar = styled.div`
  width: 173px;
  height: 147px;
  border-radius: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${(props) => props.image});
`;

const Texts = styled.div`
  flex: 1;
  margin-left: 14px;
  border-radius: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${(props) => props.image});
`;

const HeaderLine = styled.div`
  font-size: 21px;
  line-height: 30px;
  font-weight: 400;
  margin: 6px 52px 2px 0;
  & strong {
    font-size: 25px;
    font-weight: 700;
    margin-right: 6px;
  }
`;
const TextLine = styled.div`
  font-size: 13px;
  line-height: 26px;
  font-weight: 400;
  ${(props) => props.status === 'COMMITTED' && ` color: ${theme.colors.red}`};
  ${(props) => props.status === 'ON_HOLD' && ` color: ${theme.colors.gold}`};
  ${(props) => props.status === 'AVAILABLE' && ` color: ${theme.colors.green}`};
  ${((props) => props.status === 'COMMITTED' || props.status === 'ON_HOLD' || props.status === 'AVAILABLE') && 'font-weight: 700;'};
  svg {
    vertical-align: middle;
    margin-right: 8px;
  }
`;
const StatusLine = styled.span`
  padding: 0 5px;
  background-color: ${theme.colors.transparentWhite5};
  border-radius: 5px;
`;

const AgencyLogo = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 60px;
  height: 60px;
  background-color: ${theme.colors.white};
  border-radius: 11px;
  cursor: pointer;
  & img {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 50px;
  }
`;
