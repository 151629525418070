import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';
import theme from '../../styles/theme.styles';
import Button from '../../components/_FormElements/Button';
import LogoLogin from '../../components/assets/LogoLogin.svg.js';

import { DataContext } from '../../contexts/dataContext';
import { resetPassword } from '../../services/authService';

const initialFormState = {
  email: '',
  code: '',
  password: '',
};

export default function ChangePasswordConfirmationScreen(props) {
  const navigate = useNavigate();

  const dataContext = useContext(DataContext);
  const { user } = dataContext;

  if (user !== 'notLoggedIn') {
    navigate('/app/', { replace: true });
  }

  return (
    <LoginScreenLayout>
      <LoginScreenLayoutContent>
        <LogoColumn>
          <LogoLogin />
        </LogoColumn>
        <LoginColumn>
          <TextBox>
            <HeaderTitle>Change your Password</HeaderTitle>
            <SubTitle>Your password has been changed successfully</SubTitle>
          </TextBox>
          <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.415 1.415L6 0L0 6L6 12L7.415 10.585L2.83 6L7.415 1.415Z" fill="white" />
          </svg>
          <BackLink onClick={() => navigate('/login')}>Back to Login</BackLink>
        </LoginColumn>
      </LoginScreenLayoutContent>
    </LoginScreenLayout>
  );
}

const LoginScreenLayout = styled.div`
  display: flex;
  width: 100vw;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  background-image: url('https://res.cloudinary.com/dsvz8cktr/image/upload/c_scale,q_auto:low,w_1026/v1640345226/UI_things/WebBackground_zdwymz.jpg');
  backgroung-position: center;
  background-size: cover;
`;
const LoginScreenLayoutContent = styled.div`
  display: flex;
  width: 100vw;
  justify-content: center;
  font-family: ${theme.fonts.family1};
  align-items: flex-start;
`;
const LogoColumn = styled.div`
  text-align: right;
  margin-right: 56px;
`;
const LoginColumn = styled.div`
  width: 460px;
  margin-left: 56px;
  padding: 54px 68px 24px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 13px;
`;
const HeaderTitle = styled.h3`
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 32px;
`;
const BackLink = styled.h1`
  display: inline;
  font-size: 16px;
  font-family: ${theme.fonts.gotham13Regular};
  text-decoration: none;
  color: ${theme.colors.white};
  font-weight: 700;
  cursor: pointer;
  margin-left: 10px;
`;
const SubTitle = styled.p`
  display: inline;
  font-size: 15px;
  font-family: ${theme.fonts.gotham13Regular};
  text-decoration: none;
  color: ${theme.colors.white};
`;
const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 32px;
`;
