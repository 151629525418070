import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import theme from '../../styles/theme.styles';

function InputSelectField(allProps) {
  const {
    value = false,
    children = null,
    fieldName,
    formikForm,
    onChangeValueFormik,
    options,
    role,
    setRole,
    data,
  } = allProps;

  const getIndex = (options, value) => {
    if (data.role && value === null) {
      value = data.role;
      setRole(data.role);
    }
    return options.findIndex((item) => item.dbname === value);
  };

  useEffect(() => {
    onChangeValueFormik({ form: formikForm, fieldName, value: role });
  }, [role]);

  const handlePress = (index, type) => {
    setRole(type);
  };

  return (
    <RoleBox>
      {
        options.map((item, index) => (
          <ClickableArea key={index} onClick={() => { handlePress(index, item.dbname); }}>
            <RoleButton
              style={{
                backgroundColor: getIndex(options, value) === index
                  ? theme.colors.royalBlue
                  : theme.colors.transparentWhite2,
              }}
            >
              <RoleText>{item.title}</RoleText>
            </RoleButton>
          </ClickableArea>
        ))
      }
    </RoleBox>

  );
}

export default InputSelectField;

const ClickableArea = styled.div`
    cursor: pointer;
`;
export const RoleButton = styled.div`
    border-radius: 20px;
  background-color: ${theme.colors.transparentWhite2};
  padding:10px;
  margin-bottom: 7px;
  
`;
export const RoleBox = styled.div`
    margin: 20px 0;
    flex: 1;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const RoleText = styled.p`
  font-family: GothamBold;
  font-size: 16px;
  line-height: 25px;
  color: ${theme.colors.white};
  text-align: center;
`;
