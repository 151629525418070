import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from '../../styles/theme.styles';

function TextArea({
  onChange, value, placeholder, name, onBlur, error,
}) {
  const onTextAreaChange = (e) => {
    onChange(e);
  };

  return (
    <Container>
      <STextArea
        onChange={onTextAreaChange}
        placeholder={placeholder}

        name={name}
        onBlur={onBlur}
        error={error}
        defaultValue={value}
      />
    </Container>
  );
}

TextArea.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

TextArea.defaultProps = {
  placeholder: '',
  value: '',
  onChange: () => {},
};

export default TextArea;

const Container = styled.div`
  position: relative;
  display: block;
`;

const STextArea = styled.textarea`
  resize: none; 
  width: 100%;
  height: 180px;
  padding: 13px 20px 11px 20px;
  line-height: 1.2em;
  font-family: ${theme.fonts.family1};
  font-size: 15px;
  border: 1px solid rgba(255, 255, 255, 0);
  ${(props) => props.error && `
  border: 1px solid ${theme.colors.alertPink};
  `}
  border-radius: 4px;
  background-color: ${theme.colors.transparentWhite2};
  color: white;
  &:focus {
    border-color: ${theme.colors.white};
  }
`;
