import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
  Link,
} from 'react-router-dom';
import './styles/reset.css';

import Amplify from 'aws-amplify';

// Screens
import Root from './screens/root';
import LoginScreen from './screens/Login/Login';
import SearchResult from './screens/SearchResult/SearchResult';
import Agencies from './screens/Agencies/Agencies';
import Favorites from './screens/Favorites/Favorites';
import Feedback from './screens/Feedback/Feedback';
import Settings from './screens/Settings/Settings';
import ChangePassword from './screens/Settings/ChangePassword';
import DeleteAccount from './screens/Settings/DeleteAccount';
import AthleteView from './screens/AthleteView/AthleteView';
import AgencyView from './screens/Agencies/AgencyView';
import ForgotPassword from './screens/ForgotPassword/ForgotPassword';
import ResetForgotPassword from './screens/ForgotPassword/ResetForgotPasswordForm';
import ChangePasswordConfirmation from './screens/ForgotPassword/ChangePasswordConfirmation';
import OnBoarding from './screens/OnBoarding/Onboarding';
// OnlyDesktop
import OnlyDesktop from './components/OnlyDesktop';

// Helpers
import Loading from './components/Loading';

import reportWebVitals from './reportWebVitals';

// For data
import DataContextProvider, { DataContext } from './contexts/dataContext';

// Amplify
const awsmobile = {
  aws_cognito_identity_pool_id:
    process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id:
    process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  oauth: {},
  aws_appsync_graphqlEndpoint:
    process.env.REACT_APP_AWS_APPSYNC_GRAPHQLENDPOINT,
  aws_appsync_region: 'eu-west-1',
  aws_appsync_authenticationType:
    process.env.REACT_APP_AWS_APPSYNC_AUTHENTICATIONTYPE,
  aws_appsync_apiKey: process.env.REACT_APP_AWS_APPSYNC_APIKEY,
};
Amplify.configure(awsmobile);

// Contexts

function TheRoutes({ children }) {
  const dataContext = useContext(DataContext);
  const { user } = dataContext;
  if (!user) {
    return <Loading forceVisible />;
  }

  return (
    <Routes>
      <Route path="/" element={<Root />} />
      <Route path="/login/" element={<LoginScreen />} />
      {user !== 'notLoggedIn' && (
        <>
          <Route path="/app/" element={<SearchResult />}>
            <Route path="athlete_:athleteId" element={<AthleteView />} />
          </Route>
          <Route path="/agencies/" element={<Agencies />}>
            <Route path="agency_:agencyId" element={<AgencyView />} />
          </Route>
          <Route path="/favorites/" element={<Favorites />}>
            <Route path="athlete_:athleteId" element={<AthleteView />} />
          </Route>
          <Route path="/feedback/" element={<Feedback />} />
          <Route path="/settings/" element={<Settings />} />
          <Route path="/change-password/" element={<ChangePassword />} />
          <Route path="/delete-account/" element={<DeleteAccount />} />
          {/* <Route path="/settings/" element={<Settings />}>
            <Route path="change-password" element={<ChangePassword />} />
          </Route> */}
        </>
      )}
      <Route
        path="*"
        element={<Navigate to="/login/" replace />}
      />
      <Route path="/forgot-password/" element={<ForgotPassword />} />
      <Route path="/reset-password/" element={<ResetForgotPassword />} />
      <Route path="/password-confirmation/" element={<ChangePasswordConfirmation />} />
      <Route path="/onboarding/" element={<OnBoarding />} />
    </Routes>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <OnlyDesktop />
      <DataContextProvider>
        <TheRoutes />
      </DataContextProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
