import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from '../../styles/theme.styles';
import { shortenBody } from '../../helpers';

// standard content for type: text
function InfoCardBody({ body }) {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const short = shortenBody(body, 100);
  return (
    <>
      <InfoBody>
        {isOpen === true && short ? body : short}
        {!short && body}
      </InfoBody>
      { short
        && (
        <InfoLink styleNumber={4} onClick={toggle}>
          {!isOpen ? 'Read More...' : 'Read Less'}
        </InfoLink>
        )}
    </>
  );
}

function InfoCardBox({ content, type }) {
  if (content === null) return (<div />);
  const itemKeys = Object.keys(content);
  if (type === 'showcase') {
    const {
      city, country, date, showcase_name,
    } = content;
    return (
      <ValueBox>
        <ValueText>
          {' '}
          {showcase_name}
          ,
          {' '}
          {city}
          {' '}
        </ValueText>
        <ValueText>
          {' '}
          <ValueText isBold>{country}</ValueText>
          ,
          {' '}
          {date}
          {' '}
        </ValueText>
      </ValueBox>

    );
  }
  if (type === 'videolist') {
    const { metadata, url } = content;
    return (
      <a href={url} target="_blank" rel="noreferrer">
        <ValueBox>
          <ValueText>
            {' '}
            {metadata || url}
            {' '}
          </ValueText>
        </ValueBox>
      </a>

    );
  }
  return (
    <ValueBox>
      {itemKeys.map((itemKey, i) => <ValueText children={content[itemKey]} key={itemKey} />)}
    </ValueBox>
  );
}

function InfoCard({ title, body, type }) {
  if (body === null || body === '' || body === [] || typeof body === 'undefined') return null;
  return (
    <StyledInfoCard>
      <InfoTitle>{title}</InfoTitle>

      { type === 'text' && <InfoCardBody body={body} />}
      { type !== 'text' && body && body.map((item, i) => <InfoCardBox content={item} key={i} type={type} />)}

    </StyledInfoCard>
  );
}

export default InfoCard;

export const StyledInfoCard = styled.div`
  background-color: ${theme.colors.transparentWhite2};
  border-radius: 6px;
  padding: 13px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex: 1;
  & a {
    color: ${theme.colors.white};
  }
`;
export const InfoTitle = styled.div`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  color: ${theme.colors.white};
  text-align: left;
  padding: 8px 0 18px;

`;
export const InfoBody = styled.div`
font-family: "Open Sans";
font-style: normal;
font-size: 15px;
line-height: 24px;
  color: ${theme.colors.white};
  text-align: left;
`;
export const InfoLink = styled.div`
flex-grow:0;
font-family: "Open Sans";
font-style: normal;
font-size: 15px;
font-weight: bold;
line-height: 24px;
color: ${theme.colors.royalBlue};
text-align: center;
align-self:flex-end;
cursor: pointer;
`;

export const ValueBox = styled.div`
  background-color: ${theme.colors.transparentWhite2};
  padding: 12px ;
  border-radius: 6px;
  display: flex;
  align-items: stretch;
  align-content: stretch;
  justify-content: center;
  flex-direction:column;
  margin-bottom:12px;
  flex:1;
  ${(props) => props.valueText === 'Standby'
    && `background-color: ${props.theme.colors.transparentYellow};`
};

`;

export const ValueText = styled.div`
  font-family: ${theme.fonts.valueBoxText.family};
  font-size: ${theme.fonts.valueBoxText.size};
  font-weight: ${theme.fonts.valueBoxText.weight};
  line-height: ${theme.fonts.valueBoxText.lineHeight};
  color: ${theme.colors.white};
  ${(props) => props.isBold === true && `
    font-family: GothamBold;
    font-weight:700
  `}
`;
