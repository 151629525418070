import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from '../../styles/theme.styles';
import { phoneSplitToCountryCodeAndNumber } from '../../helpers';
import Agent from './Agent';

function AgencyInfo({ agency }) {
  const {
    name,
    logo,
    country,
    contact_phone,
    athleteCount,
    website,
  } = agency;

  const phone = phoneSplitToCountryCodeAndNumber(contact_phone);

  return (
    <AgencyInfoContainer>
      <FirstBlock>
        <BlockAvatar>
          <Avatar>
            <AvatarImage image={logo} />
          </Avatar>
        </BlockAvatar>
        <Texts>
          <HeaderLine>
            <strong>{name}</strong>
          </HeaderLine>
          <TextLine>{country.join(' | ')}</TextLine>
          {
            (phone) ? (
              <TextLine>
                (+
                {phone.countryCode}
                )
                {' '}
                {phone.number}
              </TextLine>
            ) : null
          }
          <TextLine>
            <LinkToWebsite href={website} target="_blank">GO TO WEBSITE</LinkToWebsite>
            <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.0376 0.00537128C1.32813 0.00537133 1.56055 0.104981 1.75977 0.295899L6.69043 5.16846C6.94776 5.42578 7.06397 5.68311 7.07227 5.99854C7.07227 6.31397 6.94775 6.58789 6.69873 6.82861L1.75977 11.7012C1.56055 11.9004 1.31982 12 1.0376 12C0.464844 12 8.12759e-08 11.5352 1.81419e-07 10.9624C2.30765e-07 10.6802 0.124512 10.4146 0.332032 10.207L4.61524 5.99854L0.332033 1.79004C0.116213 1.58252 1.86644e-06 1.3252 1.91724e-06 1.03467C2.01593e-06 0.470215 0.464846 0.00537118 1.0376 0.00537128Z" fill="white" />
            </svg>

          </TextLine>
          <AthleteCount>
            <svg
              width={13}
              height={14}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.625 3.5a1.75 1.75 0 1 0 0-3.5 1.75 1.75 0 0 0 0 3.5ZM2.625 3.5a1.75 1.75 0 1 0 0-3.5 1.75 1.75 0 0 0 0 3.5ZM12.247 9.144l-.398-3.98a.872.872 0 0 0-.87-.789H8.271a.872.872 0 0 0-.87.787l-.399 3.982a.44.44 0 0 0 .435.481h.479l.358 3.588c.045.448.42.787.872.787h.958c.452 0 .826-.34.87-.787l.36-3.588h.478a.435.435 0 0 0 .435-.481ZM5.247 9.144l-.398-3.98a.872.872 0 0 0-.87-.789H1.271a.872.872 0 0 0-.87.787L.001 9.145a.44.44 0 0 0 .435.481h.479l.358 3.588c.045.448.42.787.872.787h.958c.452 0 .826-.34.87-.787l.36-3.588h.478a.44.44 0 0 0 .435-.481Z"
                fill="#fff"
              />
            </svg>
            {athleteCount}
          </AthleteCount>
        </Texts>
      </FirstBlock>
      <SecondBlock>
        <HeaderLine>
          Agency advisors

        </HeaderLine>
        <AgentList>
          { agency.agents.items.map((agent) => (
            <Agent key={agent.id} agent={agent} />
          ))}
        </AgentList>
      </SecondBlock>
    </AgencyInfoContainer>
  );
}

AgencyInfo.propTypes = {
  athlete: PropTypes.object,
  agency: PropTypes.shape({
    name: PropTypes.string,
    logo: PropTypes.string,
    country: PropTypes.string,
    contact_phone: PropTypes.string,
    athleteCount: PropTypes.string,
    website: PropTypes.string,
  }),
};

AgencyInfo.defaultProps = {
  athlete: {},
  agency: {
    name: '',
    logo: '',
    country: '',
    contact_phone: '',
    athleteCount: '',
    website: '',
  },
};

export default AgencyInfo;

const AgencyInfoContainer = styled.div`
  position: relative;
  margin-bottom: 16px;
`;

const FirstBlock = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-radius: 13px;
  color: ${theme.colors.white};
  text-decoration: none;
  background-color: ${theme.colors.transparentWhite2};
`;

const BlockAvatar = styled.div`
  flex:
`;

const Avatar = styled.div`
  position: relative;
  width: 100%;
  height: 183px;
  border-radius: 10px;
  background-color: white;
`;
const AvatarImage = styled.div`
  position: absolute;
  top: 25px;
  right: 25px;
  bottom: 25px;
  left: 25px;
  border-radius: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${(props) => props.image});
`;

const Texts = styled.div`
  flex: 1;
  margin-top: 14px;
`;

const HeaderLine = styled.div`
  font-size: 21px;
  line-height: 30px;
  font-weight: 400;
  margin: 6px 52px 2px 0;
  & strong {
    font-size: 25px;
    font-weight: 700;
    margin-right: 6px;
  }
`;
const TextLine = styled.div`
  font-size: 13px;
  line-height: 26px;
  font-weight: 400;
  
  svg {
    vertical-align: middle;
    margin-right: 8px;
  }
`;
const AthleteCount = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 8px 10px;
  background-color: rgba(255, 255, 255, 0.1);
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  font-size: 14px;
  font-weight: 400;
  & svg {
    vertical-align: middle;
    margin-right: 8px;
  }
`;

const SecondBlock = styled.div`
  margin-top: 16px;
  padding: 12px;
  border-radius: 13px;
  color: ${theme.colors.white};
  text-decoration: none;
  background-color: ${theme.colors.transparentWhite2};
`;

const AgentList = styled.div`
  padding-top: 32px;
  font-size: 0;
  & > *{
    margin-bottom: 32px;
    display: flex;
    @media ${theme.mq.desktop} {
      display: inline-flex;
      width: calc(50% - 16px);
      margin: 0 16px 32px 0;
    }
  }
`;

const LinkToWebsite = styled.a`
  color: ${theme.colors.white};
  text-decoration: none;
  font-weight: 700;
  font-size: 12px;
  margin-right: 8px;
  vertical-align: middle;
`;
