import React, { useState } from 'react';
import styled from 'styled-components';
import {
  BrowserView, MobileView, isBrowser, isMobile,
} from 'react-device-detect';
import theme from '../../styles/theme.styles';
import LogoLogin from '../assets/LogoLogin.svg.js';
import AppleBadget from '../assets/AppleBadget.svg.js';
import AndroidBadget from '../assets/AndroidBadget.svg.js';

function OnlyDesktop() {
  const [isForcedDesktop, setForcedDesktop] = useState(false);

  const switchToDesktop = () => {
    setForcedDesktop(true);
  };

  function ArrowR() {
    return (
      <svg width={22} height={40} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.445 1.316 20.13 20 1.445 38.684"
          stroke="#fff"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
  function ArrowL() {
    return (
      <svg width={22} height={40} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M20.163 1.316 1.48 20l18.684 18.684"
          stroke="#fff"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <>
      {/* 👇 This will show to user width no touch screen but window small. */}
      <BrowserView>
        <SOnlyBigScreens>
          <LogoBox><LogoLogin /></LogoBox>
          <TextBox>
            <strong>Please, enlarge the window.</strong>
            This application is designed for desktop computers. For a proper user experience, please enlarge the window.To improve the experience on mobile devices, we are preparing applications for iOS and Android which will be ready for download from the respective stores shortly.
          </TextBox>
          {/* <Badgets>
            <a href="https://apps.apple.com/app/alliance-athletes/id1580879197" target="_blank" title="Alliance Athletes App for iOS" rel="noreferrer">
              <AppleBadget />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.allianceathletes.AllianceAthletes" target="_blank" title="Alliance Athletes App for Android" rel="noreferrer">
              <AndroidBadget />
            </a>
          </Badgets> */}
          <Arrows>
            <ArrowL />
            <ArrowL />
            <ArrowR />
            <ArrowR />
          </Arrows>
        </SOnlyBigScreens>
      </BrowserView>

      {/* 👇 This will show to mobile and touch screen users, at any screen size. But if screen size is big enough, they can force desktop version pressing a button. */}
      { isMobile && !isForcedDesktop
                && (
                <SOnlyDesktop>
                  <LogoBox><LogoLogin /></LogoBox>
                  <TextBox>
                    <strong>This application is designed for desktop computers.</strong>
                    To improve the experience on mobile devices, we are preparing applications for iOS and Android which will be ready for download from the respective stores shortly.
                  </TextBox>
                  {/* <Badgets>
                    <a href="https://apps.apple.com/app/alliance-athletes/id1580879197" target="_blank" title="Alliance Athletes App for iOS" rel="noreferrer">
                      <AppleBadget />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.allianceathletes.AllianceAthletes" target="_blank" title="Alliance Athletes App for Android" rel="noreferrer">
                      <AndroidBadget />
                    </a>
                  </Badgets> */}
                  <SwitchToDesktop onClick={switchToDesktop}>Switch to desktop version.</SwitchToDesktop>
                </SOnlyDesktop>
                )}
    </>

  );
}
export default OnlyDesktop;

const SOnlyDesktop = styled.div`
    position: fixed;
    display: flex;
    overflow-y: auto;
    flex-direction: column;
    padding: 48px 24px;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2000;
    background-color: ${theme.colors.midnightBlue};
    // @media(min-width: 1024px){
    //     display: none;
    // }
`;

const SOnlyBigScreens = styled.div`
    position: fixed;
    display: flex;
    overflow-y: auto;
    flex-direction: column;
    padding: 48px 24px;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2000;
    background-color: ${theme.colors.midnightBlue};
    @media(min-width: 1024px){
        display: none;
    }
`;
const SwitchToDesktop = styled.div`
    display: none;
    margin-top: 14px;
    text-decoration: underline;
    @media(min-width: 1024px){
        display: block;
    }
`;

const Arrows = styled.div`
    @keyframes tocleft {
        0% { transform: translateX(0); }
        90% { transform: translateX(60px); }
        100% { transform: translateX(0); }
    }
    @keyframes tocright {
        0% { transform: translateX(0); }
        90% { transform: translateX(-60px); }
        100% { transform: translateX(0); }
    }
    & > * {
        position: absolute;
        &:nth-child(1),
        &:nth-child(3){
            top: 30%;
        }
        &:nth-child(2),
        &:nth-child(4){
            top: 70%;
        }
        &:nth-child(1),
        &:nth-child(2){
            left: 20px;
            animation: tocleft 1s 1s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
        }
        &:nth-child(3),
        &:nth-child(4){
            right: 20px;
            animation: tocright 1s 1s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
        }
    }
`;

const LogoBox = styled.div`
    
`;
const TextBox = styled.div`
    margin: 30px 0 23px 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5em;
    letter-spacing: 0.374px;
    text-decoration: none;
    color: white;
    & strong{
        text-align: center;
        font-size: 16px;
        margin: 0 0 24px 0;
        display: block;
        font-weight: 700;
    }
`;
const Badgets = styled.div`
    text-align: center;
    & > *{
        display: block;
        margin: 16px auto;
    }
`;
