export const getUserStoredData = () => {
  const jsonValue = localStorage.getItem('userData');
  return jsonValue != null ? JSON.parse(jsonValue) : null;
};

export const setUserStoredData = (value) => {
  const jsonValue = JSON.stringify(value);
  localStorage.setItem('userData', jsonValue);
};

export const removeUserStoredData = () => {
  localStorage.removeItem('userData');
};
