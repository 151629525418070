import React, { useState } from 'react';
import { Formik, Field } from 'formik';
import Stepper from '../../components/_FormElements/Stepper';
import RoleButtons from '../../components/_FormElements/RoleButtons';
import {
  FormLayout,
  HeaderTitle,
  HeaderInfoText,
  Form,
  Button,
  ErrorText,
} from './OnBoarding.style';

function RoleStep({
  setStep,
  step,
  data,
  setData,
}) {
  const [role, setRole] = useState(null);

  // const {data, setData} = useContext(SignUpContext)

  const options = [
    {
      title: 'HEAD COACH',
      dbname: 'HEAD_COACH',
    },
    {
      title: 'ASSISTANT COACH',
      dbname: 'ASSISTANT_COACH',
    },
    {
      title: 'GRADUATE ASSISTANT COACH',
      dbname: 'GRADUATE_ASSISTANT_COACH',
    },
    {
      title: 'GOALKEEPER COACH',
      dbname: 'GOALKEEPER_COACH',
    },
    {
      title: 'VOLUNTEER COACH',
      dbname: 'VOLUNTEER_COACH',
    },
  ];

  const onChangeValueFormik = ({ form, fieldName, value }) => {
    form.setFieldValue(fieldName, value);
  };

  function InputSelectRole({
    field, colors, form, ...props
  }) {
    const { values, errors, touched } = form;
    return (
      <>
        <RoleButtons data={data} value={values.role} fieldName="role" formikForm={form} onChangeValueFormik={onChangeValueFormik} options={options} role={role} setRole={setRole} />
        {touched.role && errors.role && (<ErrorText>{errors.role}</ErrorText>)}
      </>
    );
  }
  return (
    <FormLayout>
      <HeaderTitle>What role on the team?</HeaderTitle>
      <HeaderInfoText>Tell us what&lsquo;s your position on the team!</HeaderInfoText>

      <Formik
        initialValues={{ role: data.role || '' }}
        onSubmit={(values, { setSubmitting }) => {
          const { role } = values;
          setData({ ...data, role });
          setSubmitting(false);
          setStep(step + 1);
        }}
        validate={(values) => {
          const errors = {};
          if (!values.role) {
            errors.role = 'Required';
          }
          console.log(errors);
          return errors;
        }}
      >
        {({
          handleSubmit, isSubmitting,
        }) => (

          <Form onSubmit={handleSubmit}>
            <Field name="role" component={InputSelectRole} />
            <Button type="submit" disabled={isSubmitting}>Next</Button>
            <Stepper active={step - 1} />
          </Form>
        )}
      </Formik>
    </FormLayout>
  );
}

export default RoleStep;
