import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { find } from 'lodash';
import { useParams, NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import ContentWrapper from '../../components/_Layout/ContentWrapper';
import TextAreaForm from '../../components/_FormElements/TextAreaForm';
import { DataContext } from '../../contexts/dataContext';
import theme from '../../styles/theme.styles';

// AgencyInfo
import AgencyInfo from '../../components/Agencies/AgencyInfo';

function AgencyView() {
  const params = useParams();
  const { agencyId } = params;
  const dataContext = useContext(DataContext);
  const [agencyData, setAgencyData] = useState(null);

  const backRoute = {
    url: '/agencies/',
    title: 'Agencies',
  };

  const onContactFormSubmit = async (values) => {
    try {
      const execDataContentFunction = await dataContext.coachToAgencyFormSend({
        message: values.message,
        agencyId,
      });
      if (execDataContentFunction) {
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    const agency = find(dataContext.agencies, { id: agencyId });
    if (agency) {
      setAgencyData(agency);
    }
  }, [dataContext.agencies]);
  return (
    <AthleteContainer>
      {agencyData && (
        <motion.div
          initial={{
            y: '100vh',
          }}
          animate={{
            y: '0vh',
            transition: {
              duration: 0.3,
            },
          }}
          exit={{
            y: '100vh',
            transition: {
              duration: 0.3,
            },
          }}
        >
          <ContentWrapper>
            <AgencyContent1>
              <AgencyContent2>
                <AthleteLayout>
                  <HeaderSpacer />
                  <Header>
                    <TitleNav to={backRoute.url}>
                      <svg
                        width={8}
                        height={12}
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.035 11.995c-.29 0-.523-.1-.723-.29L.383 6.831C.125 6.574.008 6.317 0 6.002c0-.316.125-.59.374-.83L5.312.298c.2-.2.44-.299.723-.299.572 0 1.037.465 1.037 1.038 0 .282-.124.547-.332.755L2.457 6.001 6.74 10.21c.216.207.332.465.332.755 0 .565-.465 1.03-1.037 1.03Z"
                          fill="#fff"
                        />
                      </svg>
                      {backRoute.title}
                    </TitleNav>
                  </Header>
                  <LayoutContent>
                    <AgencyInfo agency={agencyData} />
                    <TextAreaForm
                      generalLabel="Drop us a line"
                      onSubmit={onContactFormSubmit}
                      placeholder={`Hello colleagues at ${agencyData.name},\n\nI would to connect with you about…`}
                      submitLabel="Send it!"
                    />
                  </LayoutContent>
                </AthleteLayout>
              </AgencyContent2>
            </AgencyContent1>
          </ContentWrapper>
        </motion.div>
      )}
    </AthleteContainer>
  );
}

export default AgencyView;

const AthleteLayout = styled.div``;
const AthleteContainer = styled.div`
  z-index: 1000;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 0;
`;
const AgencyContent1 = styled.div`
  width: 100%;
  height: 0;
  ::-webkit-scrollbar {
    width: 20px;
  }
  ::-webkit-scrollbar-track {
    background: ${theme.colors.transparentWhite2};
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.tennisBall};
    border: 5px solid transparent;
    border-radius: 18px;
    background-clip: padding-box;  
    width: 5px;
  }
`;
const AgencyContent2 = styled.div`
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  background-color: ${theme.colors.brandDark};
  scrollbar-color: inherit transparent;
  scrollbar-width: thin;
  ::-webkit-scrollbar {
    width: 20px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.tennisBall};
    border: 5px solid transparent;
    border-radius: 18px;
    background-clip: padding-box;  
    width: 5px;
  }
`;
const HeaderSpacer = styled.div`
  height: ${theme.sizes.header.heightBig.tabletPortrait}px;
  @media ${theme.mq.desktop} {
    height: ${theme.sizes.header.heightBig.desktop}px;
  }
`;
const Header = styled.div`
  position: fixed;
  display: flex;
  align-items: flex-end;
  width: calc(
    100% - ${theme.sizes.columns.col1.tabletPortrait}px -
      ${theme.sizes.columns.col2.tabletPortrait}px
  );
  height: ${theme.sizes.header.heightBig.tabletPortrait}px;
  top: 0;
  background: ${theme.colors.brandDark};
  background: linear-gradient(180deg, rgb(51,152,203,1) 0%, rgb(51,152,203,1) 89%, rgb(51,152,203,0) 100%);
  z-index: 10;
  @media ${theme.mq.desktop} {
    height: ${theme.sizes.header.heightBig.desktop}px;
    width: calc(
      100% - ${theme.sizes.columns.col1.desktop}px -
        ${theme.sizes.columns.col2.desktop}px
    );
  }
`;
const TitleNav = styled(NavLink)`
  margin: 30px 0 23px 0;
  font-size: 21px;
  font-weight: 700;
  line-height: 41px;
  letter-spacing: 0.374px;
  text-decoration: none;
  color: white;
  & svg {
    margin-right: 12px;
  }
`;
const LayoutContent = styled.div`
  padding-bottom: 5vh;
`;
