import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataContext } from '../../contexts/dataContext';
import SignUpStep from './SignUpStep';
import DivisionStep from './DivisionStep';
import GenderStep from './GenderStep';
import RoleStep from './RoleStep';
import PhoneStep from './PhoneStep';
import AcceptTermsStep from './AcceptTermsStep';
import ValidationStep from './ValidationStep';
import Confirmation from './Confirmation';

import {
  LoginScreenLayout,
  LoginScreenLayoutContent,
  BackLink,
  NavDiv,
} from './OnBoarding.style';

export default function OnBoarding() {
  const [data, setData] = useState({
    firstname: '',
    email: '',
    password: '',
    gender: '',
    role: '',
    division: '',
    phone: '',
    whatsapp: '',
  });

  const navigate = useNavigate();

  const dataContext = useContext(DataContext);
  const { user } = dataContext;
  const [step, setStep] = useState(0);

  if (user !== 'notLoggedIn') {
    navigate('/app/', { replace: true });
  }

  return (
    <LoginScreenLayout step={step}>
      <LoginScreenLayoutContent>

        {
          step === 0
          && (
          <SignUpStep setStep={setStep} step={step} data={data} setData={setData} />
          )
        }
        {
          step === 1
          && (
            <DivisionStep setStep={setStep} step={step} data={data} setData={setData} />)
          }
        {
          step === 2
          && (
            <GenderStep setStep={setStep} step={step} data={data} setData={setData} />)
          }
        {
          step === 3
          && (
            <RoleStep setStep={setStep} step={step} data={data} setData={setData} />
          )
        }
        {
          step === 4
          && (
            <PhoneStep setStep={setStep} step={step} data={data} setData={setData} />
          )
        }
        {
          step === 5
          && (
            <AcceptTermsStep setStep={setStep} step={step} data={data} setData={setData} />
          )
        }
        {
          step === 6
          && (
            <ValidationStep setStep={setStep} step={step} data={data} />
          )
        }
        {
          step === 7
          && (
            <Confirmation setStep={setStep} step={step} />
          )
        }

        { (step !== 0 && step !== 7) && (
          <NavDiv role="button" onClick={() => setStep(step - 1)} onKeyDown={() => setStep(step - 1)}>
            <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.415 1.415L6 0L0 6L6 12L7.415 10.585L2.83 6L7.415 1.415Z" fill="white" />
            </svg>
            <BackLink>Previous page</BackLink>
          </NavDiv>
        )}
      </LoginScreenLayoutContent>
    </LoginScreenLayout>
  );
}
