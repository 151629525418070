import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from '../../styles/theme.styles';

function TextInput({
  type, onChange, onBlur, value, isFullWidth, placeholder,
}) {
  return (
    <Container isFullWidth={isFullWidth}>
      <STextInput type={type} onChange={onChange} onBlur={onBlur} value={value} placeholder={placeholder} />
      <SearchButton isVisible={type === 'search'}>
        <svg
          width={17}
          height={17}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.809 15.99l-3.812-3.626c1.284-1.227 2.019-2.876 2.052-4.603.034-1.728-.638-3.401-1.873-4.672C11.94 1.82 10.235 1.05 8.416.943a7.328 7.328 0 00-5.004 1.569C2.019 3.627 1.134 5.208.94 6.926a6.461 6.461 0 001.434 4.81c1.113 1.368 2.74 2.276 4.542 2.535a7.378 7.378 0 005.13-1.146l3.888 3.7a.625.625 0 00.44.17.652.652 0 00.438-.174.587.587 0 00.18-.416.564.564 0 00-.183-.416zM2.239 7.672a5.279 5.279 0 01.983-3.038 5.724 5.724 0 012.596-2.01 6.07 6.07 0 013.336-.304 5.88 5.88 0 012.953 1.503 5.395 5.395 0 011.575 2.804 5.214 5.214 0 01-.332 3.163 5.553 5.553 0 01-2.127 2.455 6.005 6.005 0 01-3.207.922 6.062 6.062 0 01-2.214-.421 5.813 5.813 0 01-1.875-1.192 5.474 5.474 0 01-1.251-1.782 5.239 5.239 0 01-.437-2.1z"
            fill="#fff"
            fillOpacity={0.6}
          />
        </svg>
      </SearchButton>
    </Container>
  );
}

TextInput.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  isFullWidth: PropTypes.bool,
};

TextInput.defaultProps = {
  type: 'text', // search
  placeholder: '',
  value: '',
  onChange: () => {},
  isFullWidth: false,
};

export default TextInput;

const Container = styled.div`
  position: relative;
  display: ${(props) => (props.isFullWidth ? 'block' : 'inline-block')};
`;
const SearchButton = styled.span`
  display: ${(props) => (props.isVisible ? 'inline-block' : 'none')};
  padding: 12px;
  vertical-align: bottom;
  width: 40px;
  height: 42px;
  margin-left: -42px;
`;
const STextInput = styled.input`
  width: 100%;
  padding: 13px 20px 11px 20px;
  padding-right: ${(props) => (props.type === 'search' ? '50px' : '20px')};
  line-height: 16px;
  font-size: 13px;
  border: 1px solid rgba(255, 255, 255, 0);
  border-radius: 13px;
  background-color: ${theme.colors.transparentWhite2};
  color: white;
  &:focus {
    border-color: ${theme.colors.white};
  }
`;
