import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from '../../styles/theme.styles';

function Button({
  styleNumber, label, onClick, disabled, buttonType, tag,
}) {
  return (
    <StyledButton
      as={tag}
      type={buttonType}
      styleNumber={styleNumber}
      disabled={disabled}
      onClick={!disabled ? onClick : null}
    >
      <span>{label}</span>
    </StyledButton>
  );
}

Button.propTypes = {
  styleNumber: PropTypes.number,
  label: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  tag: PropTypes.string,
  buttonType: PropTypes.string,
};

Button.defaultProps = {
  styleNumber: 1,
  label: '',
  onClick: () => {},
  disabled: false,
  tag: 'div', // 'button',
  buttonType: 'submit', // ...
};

export default Button;

const StyledButton = styled.div`
  display: block;
  width: 100%;
  border-radius: 8px;
  border-width: 1px;
  
  ${(props) => (props.disabled ? `
  cursor: not-allowed;
  `
    : `
  cursor: pointer;
  `)}
  ${(props) => props.disabled && `
  opacity: 0.4;
  `}
  & span{
    display: block;
    width: 100 %;
    font-family: ${theme.fonts.family1};
    font-size: 17px;
    font-weight: 700;
    line-height: 48px;
    min-height: 48px;
    text-align: center;
  }
  ${(props) => props.styleNumber === 1
    && `background-color: ${theme.colors.tennisBall};
    ${'' /* border-color: ${theme.colors.royalBlue}; */}
    
    span{
      color: ${theme.colors.black};
    }
    `}

    ${(props) => !props.disabled && props.styleNumber === 1 && `
    &:hover{
      background-color: ${theme.colors.white};
      border-color: ${theme.colors.white};
    }
    &:hover span{
      color: ${theme.colors.royalBlue};
    }
    
    `
}
  ${(props) => props.styleNumber === 2
    && `background-color: ${theme.colors.white};
      border-color: ${theme.colors.white};
      span{
        color: ${theme.colors.royalBlue};
      }
      `
}


      ${(props) => !props.disabled && props.styleNumber === 2 && `
    &:hover{
        background-color: ${theme.colors.royalBlue};
      }
      &:hover span{
        color: ${theme.colors.white};
      }
      `
} 
${(props) => props.styleNumber === 3
    && `background-color: ${theme.colors.tennisBall};
    border-color: ${theme.colors.tennisBall};
    
    span{
      color: ${theme.colors.black};
    }
    `}
`;
