import React, { useState } from 'react';
import { Formik, Field } from 'formik';
import Stepper from '../../components/_FormElements/Stepper';
import GenderButtons from '../../components/_FormElements/GenderButtons';
import {
  FormLayout,
  HeaderTitle,
  HeaderInfoText,
  Form,
  Button,
  ErrorText,
} from './OnBoarding.style';

function GenderStep({
  setStep,
  step,
  data,
  setData,
}) {
  const [gender, setGender] = useState(null);

  const options = [
    {
      type: 'male',
      icon: (
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M31.2607 22.368L43.0987 10.53V23.132C43.0987 23.983 43.4437 24.755 44.0017 25.313C44.5597 25.871 45.3317 26.217 46.1827 26.216C47.8867 26.216 49.2677 24.835 49.2677 23.131V3.084C49.2677 1.382 47.8857 -0.000999458 46.1827 5.41958e-07H26.1348C24.4307 5.41958e-07 23.0497 1.381 23.0497 3.085C23.0487 4.787 24.4318 6.17 26.1338 6.169H38.7357L26.8988 18.006C20.2168 13.385 10.9608 14.042 5.01675 19.987C-1.67225 26.676 -1.67225 37.561 5.01675 44.25C11.7058 50.939 22.5908 50.939 29.2798 44.25C35.2248 38.306 35.8817 29.05 31.2607 22.368ZM9.37875 39.888C5.09475 35.604 5.09475 28.633 9.37875 24.348C13.6628 20.063 20.6338 20.064 24.9188 24.348C29.2038 28.632 29.2028 35.603 24.9188 39.888C20.6348 44.173 13.6628 44.173 9.37875 39.888Z" fill="white" />
        </svg>
      ),
    },
    {
      type: 'female',
      icon: (
        <svg width="35" height="62" viewBox="0 0 35 62" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.073 34.031V46.348H7.153C5.45 46.348 4.069 47.729 4.069 49.432C4.069 51.135 5.45 52.516 7.153 52.516H14.073V58.047C14.073 59.75 15.454 61.131 17.157 61.131C18.86 61.131 20.241 59.75 20.241 58.047V52.516H27.161C28.864 52.516 30.245 51.135 30.245 49.432C30.245 47.729 28.864 46.348 27.161 46.348H20.241V34.031C28.234 32.573 34.313 25.564 34.313 17.157C34.314 7.697 26.617 0 17.157 0C7.697 0 0 7.697 0 17.157C0 25.564 6.08 32.573 14.073 34.031ZM17.157 6.169C23.216 6.169 28.145 11.098 28.145 17.157C28.145 23.216 23.216 28.145 17.157 28.145C11.098 28.145 6.169 23.216 6.169 17.157C6.169 11.098 11.098 6.169 17.157 6.169Z" fill="white" />
        </svg>
      ),
    },
  ];

  const onChangeValueFormik = ({ form, fieldName, value }) => {
    form.setFieldValue(fieldName, value);
  };

  function InputRadioGender({
    field, colors, form, ...props
  }) {
    const { values, errors, touched } = form;
    return (
      <>
        <GenderButtons data={data} value={values.gender} fieldName="gender" formikForm={form} onChangeValueFormik={onChangeValueFormik} options={options} gender={gender} setGender={setGender} />
        {touched.gender && errors.gender && (<ErrorText>{errors.gender}</ErrorText>)}
      </>
    );
  }
  return (
    <FormLayout>
      <HeaderTitle>Male or female?</HeaderTitle>
      <HeaderInfoText>Do you coach the women&lsquo;s team or the men&lsquo;s team?</HeaderInfoText>

      <Formik
        initialValues={{ gender: data.gender || '' }}
        onSubmit={(values, { setSubmitting }) => {
          const { gender } = values;
          setData({ ...data, gender });
          setSubmitting(false);
          setStep(step + 1);
        }}
        validate={(values) => {
          const errors = {};
          if (!values.gender) {
            errors.gender = 'Required';
          }
          console.log(errors);
          return errors;
        }}
      >
        {({
          handleSubmit, isSubmitting,
        }) => (

          <Form onSubmit={handleSubmit}>
            <Field name="gender" component={InputRadioGender} />
            <Button type="submit" disabled={isSubmitting}>Next</Button>
            <Stepper active={step - 1} />
          </Form>
        )}
      </Formik>
    </FormLayout>
  );
}

export default GenderStep;
