import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { filter } from 'lodash';
import { API } from 'aws-amplify';
import theme from '../../styles/theme.styles';

import OptionSelect from '../_FormElements/OptionSelect';
import FormSlider from '../_FormElements/FormSlider';

import Button from '../_FormElements/Button';
import { DataContext } from '../../contexts/dataContext';
import { createSearchTerm } from '../../custom_graphql_queries/coachs';

function Filters({ filters, updateFilters, selectorsContent }) {
  const dataContext = useContext(DataContext);
  const [filtersToShow, setFiltersToShow] = useState(filters);
  const [filtersAreReset, setfiltersAreReset] = useState(true);
  const [value, setValue] = useState(false);
  const [sendFiltersToAPI, setSendFiltersToAPI] = useState({});
  const [canApplyFilters, setCanApplyFilters] = useState(false);

  useEffect(() => {
    setFiltersToShow(filters);
  }, [filters]);

  useEffect(() => {
    (Object.keys(filtersToShow.filters).length) ? setCanApplyFilters(true) : setCanApplyFilters(false);
  }, [filtersToShow]);

  const onChangeFilterData = (value, name) => {
    let tempFilters = { ...filtersToShow.filters };

    if (selectorsContent[name].type === 'slider') {
      if (value !== selectorsContent[name].min) {
        tempFilters = { ...tempFilters, [name]: { ge: value } };
        setSendFiltersToAPI({ ...sendFiltersToAPI, [name]: value });
      } else {
        delete tempFilters[name];
        delete sendFiltersToAPI[name];
      }
    }
    if (selectorsContent[name].type === 'select') {
      if (value !== null && value !== '---') {
        tempFilters = { ...tempFilters, [name]: { eq: value } };
        setSendFiltersToAPI({ ...sendFiltersToAPI, [name]: value });
      } else {
        delete tempFilters[name];
        delete sendFiltersToAPI[name];
      }
    }
    setFiltersToShow({ ...filters, filters: tempFilters });
  };

  const saveSearchToApi = async (filters) => {
    const values = [];
    Object.keys(sendFiltersToAPI).forEach((key) => {
      if (key === 'agencyID') {
        values.push(dataContext.agencies.find((agency) => agency.id === sendFiltersToAPI[key]).name);
      } else {
        values.push(sendFiltersToAPI[key]);
      }
    });

    try {
      await API.graphql({
        query: createSearchTerm,
        variables: {
          input: {
            search_term_text: values.join(),
            coachID: dataContext.user.attributes['custom:profile_id'],
            sport: 'Tennis',
            source_app: 'WebApp',
            search_type: 'FILTER_SEARCH',
          },
        },
      });
    } catch (e) {
      console.log('ERROR!!!!!');
      console.log(e);
    }
  };

  const applyFilters = () => {
    setfiltersAreReset(false);
    updateFilters(filtersToShow);
    saveSearchToApi(filters);
  };
  const resetFilters = () => {
    setfiltersAreReset(true);
    setValue(null);
    updateFilters({ ...filters, filters: {} });
  };
  return (
    <FiltersContainer>
      {
        Object.keys(selectorsContent).map((name, idx) => {
          if (selectorsContent[name].type === 'select') {
            return (
              <FilterSelect key={idx} content={selectorsContent[name]} name={name} onChange={onChangeFilterData} resetFilters={filtersAreReset} value={value} />
            );
          }
          if (selectorsContent[name].type === 'slider') {
            return (
              <FilterSlider key={idx} content={selectorsContent[name]} name={name} onChange={onChangeFilterData} resetFilters={filtersAreReset} />
            );
          }
        })
      }
      <ActionContainer>
        <ButtonContainer>
          <Button label="Apply" onClick={applyFilters} disabled={!canApplyFilters}>Clear filters</Button>
        </ButtonContainer>
        <ButtonContainer>
          <Button label="Reset Filters" onClick={resetFilters} disabled={filtersAreReset}>Clear filters</Button>
        </ButtonContainer>
      </ActionContainer>
    </FiltersContainer>

  );
}

function FilterSelect({
  content, name, onChange, value, resetFilters,
}) {
  const onChangeSelect = (e) => {
    onChange(e, name);
  };

  const options = [...content.valueSets];
  options.unshift(['---', content.placeholder]);
  return (
    <>
      <SelectLabel>{content.label}</SelectLabel>
      <OptionSelect options={options} onChange={onChangeSelect} defaultValue={content.placeholder} resetFilters={resetFilters} />
    </>
  );
}

function FilterSlider({
  content, name, onChange, resetFilters,
}) {
  const onChangeSelect = (e) => {
    onChange(e, name);
  };
  const {
    min, max, unit, step,
  } = content;
  return (
    <>
      <SelectLabel>{content.label}</SelectLabel>
      <FormSlider min={min} max={max} unit={unit} step={step} onChange={onChangeSelect} resetFilters={resetFilters} />
    </>
  );
}

Filters.propTypes = {
  filters: PropTypes.object.isRequired,
  selectorsContent: PropTypes.object.isRequired,
  onChange: PropTypes.func,
};

Filters.defaultProps = {};

export default Filters;

const FiltersContainer = styled.div`
  position: relative;
  padding-bottom: 34px;
`;
const SelectLabel = styled.label`
  display: block;
  margin: 16px 0 8px 0;
  font-size: 13px;
  font-weight: 400;
  color: ${theme.colors.transparentWhite4};
`;

const ActionContainer = styled.div`
  width: 100% ;
  margin: 30px 0 0 0;
`;

const ButtonContainer = styled.div`
  margin-bottom: 10px;
`;
