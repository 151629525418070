import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Outlet, useLocation } from 'react-router-dom';

import InfiniteScroll from 'react-infinite-scroll-component';
import { motion, AnimatePresence } from 'framer-motion';
import { API } from 'aws-amplify';
import ContentWrapper from '../../components/_Layout/ContentWrapper';
import Layout from '../../components/_Layout/Layout';
import { DataContext } from '../../contexts/dataContext';
import TextInput from '../../components/_FormElements/TextInput';
import ListItem from '../../components/Athletes/ListItem';
import theme from '../../styles/theme.styles';
import LoadingSvg from '../../components/assets/Loading.svg';
// filters
import RightColumn from '../../components/_Layout/RightColumn';
import Filters from '../../components/Athletes/Filters';

import { createSearchTerm } from '../../custom_graphql_queries/coachs';

function SearchResult() {
  const location = useLocation();
  const isInSearchPage = () => location.pathname === '/app/';

  const dataContext = useContext(DataContext);
  const { filteredAthletes, isDataProcessed, athletes } = dataContext;
  const [visibleItems, setVisibleItems] = useState([]);
  const [hasMore, setHasMore] = useState([]);

  const fetchData = () => {
    setTimeout(() => {
      setVisibleItems(filteredAthletes.slice(0, visibleItems.length + 10));
    }, 100);
  };

  const updateAthletes = () => {
    setVisibleItems(filteredAthletes.slice(0, 10));
  };

  useEffect(() => {
    if (isDataProcessed) {
      updateAthletes();
    }
  }, [isDataProcessed]);

  const saveSearchToApi = async (searchTermText) => {
    if (searchTermText.length > 3 && dataContext.lastSavedToApiSearchString !== searchTermText) {
      dataContext.updateLastSavedToApiSearchString(searchTermText);
      try {
        await API.graphql({
          query: createSearchTerm,
          variables: {
            input: {
              search_term_text: searchTermText,
              coachID: dataContext.user.attributes['custom:profile_id'],
              sport: 'Tennis',
              source_app: 'WebApp',
              search_type: 'TEXT_SEARCH',
            },
          },
        });
      } catch (e) {
        console.log('ERROR!!!!!');
        console.log(e);
      }
    }
  };

  // Search and filter
  const { filters, updateFilters, selectorsContent } = dataContext;
  const onSearchStringChange = (e) => {
    updateFilters({ ...filters, searchString: e.target.value });
  };

  useEffect(() => {
    (visibleItems.length === filteredAthletes.length) ? setHasMore(false) : setHasMore(true);
  }, [visibleItems]);

  useEffect(() => {
    setVisibleItems(filteredAthletes.slice(0, 10));
  }, [filteredAthletes]);

  return (
    <Layout>
      <RightColumn open={!!isInSearchPage()}>
        { isDataProcessed
        && (
        <motion.div
          animate={
            isInSearchPage() ? {
              x: '0%',
            } : {
              x: '100%',
            }
          }
        >
          <Filters
            selectorsContent={selectorsContent}
            filters={filters}
            updateFilters={updateFilters}
          />
        </motion.div>
        )}
      </RightColumn>
      <ContentWrapper>
        <SearchResultLayout>
          <SearchHeader>
            <SearchContainer>
              <TextInput
                type="search"
                placeholder="Search..."
                isFullWidth
                value={filters.searchString}
                onChange={onSearchStringChange}
                onBlur={() => saveSearchToApi(filters.searchString)}
              />
            </SearchContainer>
            <Title>Athletes</Title>
            <AthletesCounter>{`${filteredAthletes.length} of ${athletes.length} results`}</AthletesCounter>
          </SearchHeader>
          <ListScroll id="SearchScrollId">
            <SearchHeaderSpacer />

            {visibleItems.length > 0 && (
              <InfiniteScroll
                dataLength={visibleItems.length} // This is important field to render the next data
                next={fetchData}
                hasMore={hasMore}
                loader={<div style={{ position: 'absolute', left: '45%' }}><LoadingSvg /></div>}
                scrollableTarget="SearchScrollId"
                endMessage={<div style={{ width: '500px', margin: '0 auto', textAlign: 'center' }}><h4>No more athletes to show with these filters</h4></div>}
                style={{ paddingBottom: '50px' }}
              >
                {visibleItems.map((athlete) => (
                  <ListItem athlete={athlete} key={athlete.id} path={`/app/athlete_${athlete.id}`} favoriteToggle={dataContext.favoriteToggle} />
                ))}
              </InfiniteScroll>
            )}

            {visibleItems.length === 0 && <NoResults>No results...</NoResults>}
          </ListScroll>
        </SearchResultLayout>

      </ContentWrapper>
      <AnimatePresence>
        <Outlet />
      </AnimatePresence>
    </Layout>
  );
}

SearchResult.propTypes = {};

SearchResult.defaultProps = {};

export default SearchResult;

const SearchResultLayout = styled.div`
  position: relative;
`;

const SearchContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 602px;
  padding-top: 39px;
`;
const ListScroll = styled.div`
  position: absolute;
  overflow: auto;
  width: 100% ;
  top: 0;
  left: 0;
  height: 99vh;
  z-index: 1;
  padding-bottom: 20px;
  scrollbar-color: inherit transparent;
  scrollbar-width: thin;
  padding-right: 10px;
  ::-webkit-scrollbar {
    width: 20px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.tennisBall};
    border: 5px solid transparent;
    border-radius: 18px;
    background-clip: padding-box;  
    width: 5px;
    height: 150px;
  }
`;
const SearchHeaderSpacer = styled.div`
  height: ${theme.sizes.header.heightBig.tabletPortrait}px;
  @media ${theme.mq.desktop} { 
    height: ${theme.sizes.header.heightBig.desktop}px;
  }
`;
const SearchHeader = styled.div`
  position: absolute;
  width: 100% ;
  top: 0;
  left: 0;
  background: ${theme.colors.brandDark};
  background: linear-gradient(180deg, rgb(51,152,203,1) 0%, rgb(51,152,203,1) 89%, rgb(51,152,203,0) 100%);
  z-index: 2;
`;
const Title = styled.h1`
  margin: 30px 0 23px 0;
  font-size: 32px;
  font-weight: 700;
  line-height: 41px;
  letter-spacing: 0.374px;
  display: inline-block;
`;
const AthletesCounter = styled.h6`
  display: inline-block;
  float: right;
  margin: 50px 20px 23px 0;
  font-size: 14px;
  font-weight: 700;
`;
const NoResults = styled.h1`
  margin: 30px 0 23px 0;
  font-size: 15px;
  font-weight: 400;
`;
