import React, { useContext, useEffect } from 'react';
import './App.css';
import { useNavigate } from 'react-router-dom';
import { DataContext } from '../../contexts/dataContext';

function Root() {
  const dataContext = useContext(DataContext);
  const { user } = dataContext;
  const navigate = useNavigate();
  const goToApp = () => {
    navigate('/app/');
  };
  useEffect(() => {
    // esto se ejecuta siempre
    if (user !== null) {
      goToApp();
    }
  }, [user]);

  return <div className="App">Loading</div>;
}

export default Root;
