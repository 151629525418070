import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from '../../styles/theme.styles';
import FullScreenModal from '../_Layout/FullScreenModal';

function MediaBox({ featured_video }) {
  const [isOpen, setIsOpen] = useState(false);
  if (!featured_video || featured_video.url.length < 3) return null;
  const videoUrl = featured_video.url.replaceAll('.mov', '.mp4').replaceAll('.avi', '.mp4').replaceAll('.mpg', '.mp4').replaceAll('.mpeg', '.mp4');
  const poster = featured_video.url ? videoUrl.replaceAll('.mp4', '.jpg') : '';
  const onOpen = () => {
    setIsOpen(true);
  };
  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <MediaBoxContainer>
      <FullScreenModal isVisible={isOpen} onClose={onClose}>
        <Video>
          <video controls autoPlay>
            <source src={videoUrl} type="video/mp4" />
            <p>
              Your browser doesn't support HTML5 video. Here is a
              {' '}
              <a href={featured_video.url}>link to the video</a>
              {' '}
              instead.
            </p>
          </video>
        </Video>
      </FullScreenModal>
      <Box>
        <img src={poster} />
        <PlayButton onClick={onOpen}>
          <div>
            <svg width={18} height={22} fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18 11 0 21.392V.608L18 11Z" fill="#fff" />
            </svg>
          </div>
        </PlayButton>
      </Box>
    </MediaBoxContainer>
  );
}

MediaBox.propTypes = {
  featured_video: PropTypes.object,
};

MediaBox.defaultProps = {
  featured_video: {
    url: '',
  },
};

export default MediaBox;

const MediaBoxContainer = styled.div`
  position: relative;
  margin-bottom: 16px;
`;
const Box = styled.div`
  position: relative;
  & img{
    width: 100%;
    height: auto;
    border-radius: 6px;
  }
`;
const PlayButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  & > div{
    position: relative;
    width: 44px;
    height: 44px;
    border-radius: 22px;
    background-color: rgba(255, 255, 255, 0.5);
    & > svg{
      position: absolute;
      top: 11px;
      left: 16px;
    }
  }
  &:hover{
    background-color: rgba(255, 255, 255, 0.2);
  }
`;
const Video = styled.div`
  width: 100vw;
  height: 100vh;
  & > video{
    width: 100vw;
    height: 100vh;
  }
`;
