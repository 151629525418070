/* eslint-disable import/prefer-default-export */
export const createFeedback = /* GraphQL */ `
  mutation CreateFeedback(
    $input: CreateFeedbackInput!
  ) {
    createFeedback(input: $input) {
      id
  }
}
`;
