import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import theme from '../../styles/theme.styles';
import LayoutWrapper from './LayoutWrapper';

function MainMenu({ menuItems }) {
  return (
    <LayoutWrapper>
      <MainMenuContainer>
        <SMainMenu>
          <MenuTitle>Menu</MenuTitle>
          <MenuBox>
            { menuItems.map((item, idx) => <MenuItem item={item} key={idx} />)}
          </MenuBox>
        </SMainMenu>
      </MainMenuContainer>
    </LayoutWrapper>
  );
}

function MenuItem({ item }) {
  return (
    <SMenuItem color={item.color}>
      <NavLink to={item.path} end>
        <span>{item.icon}</span>
        {item.label}
      </NavLink>
    </SMenuItem>
  );
}

MainMenu.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.object),
};

MainMenu.defaultProps = {
  menuItems: [],
};

export default MainMenu;

const MainMenuContainer = styled.div`
    position: relative;
`;
const SMainMenu = styled.nav`
    position: fixed;
    padding-left: 8px;
    top: ${theme.sizes.header.height.tabletPortrait}px;
    width: ${theme.sizes.columns.col1.tabletPortrait}px;
    height: calc(100vh - ${theme.sizes.header.height.tabletPortrait}px);
    overflow-y: auto;
    @media ${theme.mq.desktop} { 
        top: ${theme.sizes.header.height.desktop}px;
        width: ${theme.sizes.columns.col1.desktop}px;
        height: calc(100vh - height: ${theme.sizes.header.height.desktop}px);
    }
`;

const MenuTitle = styled.div`
    padding-bottom: 18px;
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    color: ${theme.colors.transparentWhite4};

`;
const MenuBox = styled.ul`
    list-style: none;
    margin: 0;
    padding-top: 8px;
`;

const SMenuItem = styled.li`

    & > a {
        display: block;
        padding: 16px 0;
        font-size: 16px;
        font-weight: 400;
        text-decoration: none;
        color: ${theme.colors.white};
        opacity: 0.6;
        &.active{
            font-weight: 500;
            opacity: 1;
            & > span{
                background-color: ${(props) => props.color};
            }
        }
        &:hover{
            opacity: 1;
        }
        & > span{
            display: inline-flex;
            width: 40px;
            height: 40px;
            margin-right: 22px;
            border-radius: 14px;
            vertical-align: middle;
            align-items: center;
            justify-content: center;
            background-color: ${theme.colors.transparentWhite2};
        }
    }
`;
