export function getAge(dateString) {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age -= 1;
  }
  return age;
}

export const phoneSplitToCountryCodeAndNumber = (fullNumber) => {
  if (fullNumber) {
    const numbArray = fullNumber.split('');
    if (fullNumber.indexOf('+1') >= 0) {
      return (
        {
          countryCode: '1',
          number: numbArray.slice(2).join(''),
        }
      );
    }
    if (fullNumber.indexOf('+1') === -1) {
      return (
        {
          countryCode: numbArray.slice(1, 3).join(''),
          number: numbArray.slice(3).join(''),
        }
      );
    }
  }
  return false;
};

const cmToInch = (cm) => {
  // return Math.round(cm * 0.39370079 * 100) / 100
  const realFeet = ((cm * 0.393700) / 12);
  const feet = Math.floor(realFeet);
  const inches = Math.round((realFeet - feet) * 12);
  return `${feet}"${inches}'`;
};

export const convertToUserUnitHeight = (value) => {
  // para proxima versión donde se pueda configurar la unidad del usuario
  cmToInch(value);
};

export const shortenBody = (string, splitLength) => {
  if (string && string.split('').length > splitLength) {
    const shortString = string.split('').splice(0, splitLength).join('');
    return `${shortString}... `;
  }
  return null;
};

export const youtubeParser = (url) => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return (match && match[7].length === 11) ? match[7] : false;
};
