export const getImageAtSize = ({
  url, w, h, o, status,
}) => {
  const ops = {
    cropMode: o?.cropMode ? o.cropMode : 'thumb',
  };
  if (!url) return '';
  return url.replaceAll('image/upload/', `image/upload/c_${ops.cropMode},e_auto_brightness,w_${w},h_${h}/`);
};

export const getImageWithEffects = (url) => {
  if (!url) return '';
  return url.replaceAll('image/upload/', 'image/upload/e_grayscale/l_commited-watermark_cbwmm6/c_scale,w_0.4/fl_layer_apply/');
};
