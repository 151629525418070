import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from '../../styles/theme.styles';
import LayoutWrapper from './LayoutWrapper';

// The horizontal wraper is for the content in the middle of the page, excluding menu, filters, etc
function ContentWrapper({ children }) {
  return (
    <LayoutWrapper>
      <SContentWrapper>{children}</SContentWrapper>
    </LayoutWrapper>
  );
}
export default ContentWrapper;

ContentWrapper.propTypes = {
  children: PropTypes.element.isRequired,
};

const SContentWrapper = styled.div`
    padding: 0 ${theme.sizes.columns.col2.tabletPortrait}px 0 ${theme.sizes.columns.col1.tabletPortrait}px;
    @media ${theme.mq.desktop} { 
        padding: 0 ${theme.sizes.columns.col2.desktop}px 0 ${theme.sizes.columns.col1.desktop}px;
    }
`;
