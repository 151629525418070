import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { Formik, Field } from 'formik';
import theme from '../../styles/theme.styles';
import { DataContext } from '../../contexts/dataContext';
import Stepper from '../../components/_FormElements/Stepper';
import InputSelect from '../../components/_FormElements/InputSelect';
import {
  FormLayout,
  HeaderTitle,
  HeaderInfoText,
  Form,
  Button,
  ErrorText,
} from './OnBoarding.style';

function DivisionStep({
  setStep,
  step,
  data,
  setData,
}) {
  const [division, setDivision] = useState(null);

  const options = [
    { label: 'NCAA DI', value: 'NCAA_DI' },
    { label: 'NCAA DII', value: 'NCAA_DII' },
    { label: 'NCAA DIII', value: 'NCAA_DIII' },
    { label: 'NAIA', value: 'NAIA' },
    { label: 'NJCAA', value: 'NJCAA' },
    { label: 'JUCO', value: 'JUCO' },
  ];

  const onChangeValueFormik = ({ form, fieldName, value }) => {
    form.setFieldValue(fieldName, value);
  };

  function InputSelectDivision({
    field, colors, form, ...props
  }) {
    const { values, errors, touched } = form;
    return (
      <>
        <InputSelect data={data} value={values.division} fieldName="division" formikForm={form} onChangeValueFormik={onChangeValueFormik} options={options} division={division} setDivision={setDivision} />
        {touched.division && errors.division && (<ErrorTextLeft>{errors.division}</ErrorTextLeft>)}
      </>
    );
  }
  return (
    <FormLayout>
      <HeaderTitle>What is your division?</HeaderTitle>
      <HeaderInfoText>Please choose one option from the list below</HeaderInfoText>

      <Formik
        initialValues={{ division: data.division || '' }}
        onSubmit={(values, { setSubmitting }) => {
          const { division } = values;
          setData({ ...data, division: division.value || data.division });
          setSubmitting(false);
          setStep(step + 1);
        }}
        validate={(values) => {
          console.log(values);
          const errors = {};
          if (!values.division) {
            errors.division = 'Required';
          }
          console.log(errors);
          return errors;
        }}
      >
        {({
          handleChange, handleBlur, handleSubmit, errors, values, isSubmitting, touched,
        }) => (

          <Form onSubmit={handleSubmit}>
            <Field name="division" component={InputSelectDivision} />
            <Button type="submit" disabled={isSubmitting}>Next</Button>
            <Stepper active={step - 1} />
          </Form>
        )}
      </Formik>
    </FormLayout>
  );
}

const ErrorTextLeft = styled(ErrorText)`
    text-align: left;
`;

export default DivisionStep;
