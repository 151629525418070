import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from '../../styles/theme.styles';
import { getImageAtSize } from '../../helpers/cloudinaryHelpers';

function Agent({ agent }) {
  const pic = agent.profile_pic !== null
    ? getImageAtSize({ url: agent.profile_pic, w: 112, h: 112 })
    : '/images/icon-person.png';
  return (
    <StyledAgent key={agent.id}>
      <AgentAvatar image={pic} />
      <AgentText>
        <AgentName>{agent.name}</AgentName>
        <AgentEmail>{agent.contact_email}</AgentEmail>
      </AgentText>
    </StyledAgent>
  );
}

Agent.propTypes = {
  agent: PropTypes.object,
};

Agent.defaultProps = {
  agent: {},
};

export default Agent;

const StyledAgent = styled.div`
  
`;

const AgentAvatar = styled.div`
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${(props) => props.image});
`;
const AgentText = styled.div`
  padding: 0 24px 0 16px;
`;
const AgentName = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 1.8em;
`;
const AgentEmail = styled.div`
  font-size: 12px;
  font-weight: 300;
  line-height: 1.2em;
`;
