import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Outlet } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import {
  filter,
} from 'lodash';
import ContentWrapper from '../../components/_Layout/ContentWrapper';
import Layout from '../../components/_Layout/Layout';
import { DataContext } from '../../contexts/dataContext';
import ListItem from '../../components/Athletes/ListItem';
import theme from '../../styles/theme.styles';
// filters
import RightColumn from '../../components/_Layout/RightColumn';

function Favorites() {
  const dataContext = useContext(DataContext);
  const { athletes, isDataProcessed } = dataContext;
  const [visibleItems, setVisibleItems] = useState([]);

  const getFavoritedAthletes = (tempAthletes) => filter(tempAthletes, { isFavorited: true });

  const updateAthletes = () => {
    setVisibleItems(getFavoritedAthletes(athletes));
  };

  useEffect(() => {
    if (isDataProcessed) {
      updateAthletes();
    }
  }, [isDataProcessed]);

  useEffect(() => {
    setVisibleItems(getFavoritedAthletes(athletes));
  }, [athletes]);

  return (
    <Layout>
      <RightColumn />
      <ContentWrapper>
        <FavoritesLayout>
          <Header>
            <Title>Favorites</Title>
          </Header>
          <ListScroll>
            <HeaderSpacer />
            {visibleItems.length > 0

                && visibleItems.map((athlete) => (
                  <ListItem athlete={athlete} key={athlete.id} path={`/favorites/athlete_${athlete.id}`} favoriteToggle={dataContext.favoriteToggle} />
                ))}

            {visibleItems.length === 0 && <NoResults>No results...</NoResults>}
          </ListScroll>
        </FavoritesLayout>

      </ContentWrapper>
      <AnimatePresence>
        <Outlet />
      </AnimatePresence>
    </Layout>
  );
}

export default Favorites;

const FavoritesLayout = styled.div`
  position: relative;
`;

const HeaderSpacer = styled.div`
  height: ${theme.sizes.header.heightBig.tabletPortrait}px;
  @media ${theme.mq.desktop} { 
    height: height: ${theme.sizes.header.heightBig.desktop}px;
  }
`;
const Header = styled.div`
  position: absolute;
  display: flex;
  align-items: flex-end;
  height: ${theme.sizes.header.heightBig.tabletPortrait}px;
  width: 100% ;
  top: 0;
  left: 0;
  background: ${theme.colors.brandDark};
  background: linear-gradient(180deg, rgb(51,152,203,1) 0%, rgb(51,152,203,1) 89%, rgb(51,152,203,0) 100%);
  z-index: 2;
  @media ${theme.mq.desktop} { 
    height: ${theme.sizes.header.heightBig.desktop}px;
  }
`;
const Title = styled.h1`
  margin: 30px 0 23px 0;
  font-size: 32px;
  font-weight: 700;
  line-height: 41px;
  letter-spacing: 0.374px;
`;
const NoResults = styled.h1`
  margin: 30px 0 23px 0;
  font-size: 15px;
  font-weight: 400;
`;
const ListScroll = styled.div`
  position: absolute;
  overflow: auto;
  width: 100% ;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 1;
  padding-right: 10px;
  scrollbar-color: inherit transparent;
  scrollbar-width: thin;
  ::-webkit-scrollbar {
    width: 20px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.tennisBall};
    border: 5px solid transparent;
    border-radius: 18px;
    background-clip: padding-box;  
    width: 5px;
  }
`;
