const selectorsContent = {
  gender: {
    label: 'Gender',
    type: 'select',
    placeholder: 'Not relevant',
    valueSets: [
      ['female', 'Female'],
      ['male', 'Male'],
    ],
  },
  start_date: {
    label: 'Start date',
    type: 'select',
    placeholder: 'Any season',
    valueSets: [
      ['SPRING_2022', 'Spring 2022'],
      ['FALL_2022', 'Fall 2022'],
      ['SPRING_2023', 'Spring 2023'],
      ['FALL_2023', 'Fall 2023'],
      ['SPRING_2024', 'Spring 2024'],
      ['FALL_2024', 'Fall 2024'],
      ['SPRING_2025', 'Spring 2025'],
    ],
  },
  target_division: {
    label: 'Target Divisions',
    type: 'select',
    placeholder: 'Any division',
    valueSets: [
      ['NCAA_DI', 'NCAA DI'],
      ['NCAA_DII', 'NCAA DII'],
      ['NCAA_DIII', 'NCAA DIII'],
      ['NAIA', 'NAIA'],
      ['NJCAA', 'NJCAA'],
      ['JUCO', 'JUCO'],
    ],
  },
  country_of_competition: {
    label: 'Competition Country',
    type: 'select',
    placeholder: 'Any country',
    valueSets: [], // Comes from API
  },
  dominant_hand: {
    label: 'Dominant hand',
    type: 'select',
    placeholder: 'Not relevant',
    valueSets: [
      ['right', 'Right hand'],
      ['left', 'Left hand'],
      ['both', 'Both hands'],
    ],
  },
  psawithvideos: {
    label: 'PSA with videos',
    type: 'select',
    placeholder: 'Not important',
    valueSets: [
      ['Highlight Video', 'Highlight Video'],
      ['Other Videos', 'Other Videos'],
      ['Highlight and Other Videos', 'Highlight and Other Videos'],
    ],
  },
  current_status: {
    label: 'Current Status',
    type: 'select',
    placeholder: 'Not important',
    valueSets: [
      ['COMMITTED', 'Committed'],
      ['ON_HOLD', 'On hold'],
      ['AVAILABLE', 'Available'],
    ],
  },
  agencyID: {
    label: 'Agency',
    type: 'select',
    placeholder: 'All agencies',
    valueSets: [], // Comes from API
    // valueSets: context.agencies.map((agency) => {
    //   return [agency.id, agency.name]
    // }),
  },
  type_of_admission: {
    label: 'Type of admission',
    type: 'select',
    placeholder: 'Any type',
    valueSets: [
      ['INTERNATIONAL_TRANSFER', 'International Transfer'],
      ['FRESHMAN', 'Freshman'],
      ['US_TRANSFER', 'US Transfer'],
      ['GRADUATE', 'Graduate'],
      ['OTHER', 'Other'],
    ],
  },
  sat_score: {
    label: 'SAT Score',
    type: 'slider',
    min: 800,
    max: 1600,
    step: 50,
    unit: '',
  },
  toefl_score: {
    label: 'TOEFL Score',
    type: 'slider',
    min: 0,
    max: 120,
    step: 20,
    unit: '',
  },
  estimated_gpa: {
    label: 'GPA Score',
    type: 'slider',
    min: 0,
    max: 4,
    step: 0.01,
    unit: '',
  },
  utr: {
    label: 'UTR',
    type: 'slider',
    min: 0,
    max: 16.50,
    step: 0.5,
    unit: '',
  },
  national_ranking: {
    label: 'National ranking',
    type: 'slider',
    min: 0,
    max: 1000,
    step: 1,
    unit: '',
  },
  international_ranking: {
    label: 'International ranking',
    type: 'slider',
    min: 0,
    max: 1000,
    step: 1,
    unit: '',
  },
};

export default selectorsContent;
