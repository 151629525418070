import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from '../../styles/theme.styles';

function OptionSelect({
  type, onChange, isFullWidth, options, defaultValue, resetFilters,
}) {
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  const onSelectChange = (e) => {
    setSelectedValue(e.target.value);
    onChange(e.target.value);
  };

  useEffect(() => {
    if (resetFilters === true) {
      setSelectedValue(defaultValue);
    }
  }, [resetFilters]);

  return (
    <Container isFullWidth={isFullWidth}>
      <Select value={selectedValue} onChange={onSelectChange}>
        {
          options.map((option, idx) => <Option key={idx} value={option[0]}>{option[1]}</Option>)
        }
      </Select>
    </Container>
  );
}

OptionSelect.propTypes = {
  type: PropTypes.string,
  options: PropTypes.array,
  name: PropTypes.string,
  onChange: PropTypes.func,
  isFullWidth: PropTypes.bool,
};

OptionSelect.defaultProps = {
  type: 'text', // search
  options: [],
  name: '',
  onChange: () => {},
  isFullWidth: true,
};

export default OptionSelect;

const Container = styled.div`
  position: relative;
  display: ${(props) => (props.isFullWidth ? 'block' : 'inline-block')};
`;
const Select = styled.select`
  display: block;
  width: 100%;
  appearance: none;
  position: relative;
  padding: 18px 28px 8px 0;
  font-size: 13px;
  line-height: 100%;
  font-weight: 700;
  color: ${theme.colors.white};

  background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.9946 1.0376C11.9946 1.32813 11.895 1.56055 11.7041 1.75977L6.83154 6.69043C6.57422 6.94775 6.31689 7.06396 6.00146 7.07227C5.68603 7.07227 5.41211 6.94775 5.17139 6.69873L0.298828 1.75977C0.0996094 1.56055 -1.23365e-08 1.31982 0 1.0376C2.50359e-08 0.464844 0.464844 -2.50359e-08 1.0376 0C1.31982 1.23365e-08 1.58545 0.124512 1.79297 0.332031L6.00146 4.61523L10.21 0.332032C10.4175 0.116211 10.6748 4.21255e-07 10.9653 4.33954e-07C11.5298 4.58628e-07 11.9946 0.464844 11.9946 1.0376Z' fill='white'/%3E%3C/svg%3E%0A");
  background-position: right 10px top 55%;
  background-repeat: no-repeat;


  background-color: transparent;
  border: 0;
  box-shadow: none;
  cursor: pointer;
`;
const Option = styled.option`
  color: ${theme.colors.midnightBlue};
`;

// import Select from 'react-select'

// const OptionSelect = ({ type, onChange, value, isFullWidth, options }) => {
//   const customStyles = {

//     menu: (provided, state) => ({
//       ...provided,

//     }),
//     container: (provided, state) => ({
//       ...provided,
//       width: '100%',
//     }),
//     control: (provided, state) => ({
//       ...provided,
//       // none of react-select's styles are passed to <Control />
//       backgroundColor: 'transparent',
//       border: 0,
//       boxShadow: 'none',
//       cursor: 'pointer',
//     }),
//     placeholder: (provided, state) => ({
//       ...provided,
//       // none of react-select's styles are passed to <Control />
//       fontSize: '13px',
//       fontWeight: '700',
//       color: theme.colors.white,
//       opacity: state.isSelected ? 1 : 0.6,
//     }),
//     singleValue: (provided, state) => ({
//       ...provided,
//       // none of react-select's styles are passed to <Control />
//       fontSize: '13px',
//       fontWeight: '700',
//       color: theme.colors.white,
//     }),
//     indicatorSeparator: (provided, state) => ({
//       ...provided,
//       display: 'none',
//     }),
//     // singleValue: (provided, state) => {
//     //   const opacity = state.isDisabled ? 0.5 : 1;
//     //   const transition = 'opacity 300ms';

//     //   return { ...provided, opacity, transition };
//     // }
//   }

//   return (
//     <Container isFullWidth={isFullWidth}>
//       <Select styles={customStyles} options={options} />
//     </Container>
//   )
// }
